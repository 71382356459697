import React, { useState } from 'react';
import {
	Grid,
	Container,
	Box,
	useTheme,
	useMediaQuery,
	Button,
} from '@mui/material';
import bg from '../../assets/images/bg-4.png';
import { useNavigate } from 'react-router-dom';
import { userRegisterDispatch } from '../../reducers/HomeReducer';
import { useDispatch } from 'react-redux';

const AIGenerated = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const handleRegister = () => {
		let form = {
			email: email,
		};
		dispatch(userRegisterDispatch(form, navigate));
	};

	return (
		<>
			<Container>
				<div
					style={
						{
							// background: `url(${bg})`,
							// height: '500px',
							// borderRadius: 20,
							// marginBottom: 50,
							// marginTop: 100,
							// padding: 60,
							// backgroundImage: 'linear-gradient(to right, #13a8a6, #48d0ac)',
						}
					}>
					{/* <p style={{ textAlign: 'center', fontSize: 26 }}>
						5-minutes miles?Starting training your clients in less than that
					</p> */}
					<Container style={{ marginTop: 50, marginBottom: 50 }}>
						<Grid
							className='grad'
							container
							// spacing={4}
							style={{ marginTop: 50, alignItems: 'center' }}>
							<Grid item xs={12} lg={2}></Grid>
							<Grid item xs={12} lg={4}>
								<Box
									style={{
										boxShadow: matches ? '' : '0 3px 10px rgb(0 0 0 / 0.2)',
										padding: matches ? 0 : 60,
										borderTopLeftRadius: 15,
										borderBottomLeftRadius: 15,
									}}>
									<h1
										style={{
											textAlign: 'center',
											// color: 'white',
											fontSize: matches ? 26 : 36,
											fontWeight: 'bold',
											textShadow: '2px 2px 4px rgba(0,0,0,.3)',
										}}>
										Get your free{' '}
										<span style={{ color: '#13A8A6' }}>
											AI Generated Meal Plan
										</span>
									</h1>
								</Box>
							</Grid>
							<Grid item xs={12} lg={5}>
								<Box
									// mt={4}
									style={{
										background: 'white',
										padding: 30,
										borderRadius: 5,
										width: matches ? '' : '100%',
										boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
										borderRadius: 10,
									}}>
									{/* <h1 style={{ fontSize: 36, fontWeight: 'bold' }}>
										Ready? Let's do this.
									</h1> */}
									{/* <br></br> */}
									<p style={{ fontSize: matches ? 16 : 26 }}>
										Your meal plan is{' '}
										<span style={{ color: '#13A8A6', fontWeight: 'bold' }}>
											UNIQUE
										</span>{' '}
										to you. Cutting edge AI instantly learns your favorite
										foods,dietary preferences, allergies and even your fitness
										goals
									</p>
									<input
										type='text'
										onChange={(e) => setEmail(e.target.value)}
										placeholder='Enter Your Email'
										style={{
											border: '1px solid grey',
											padding: 12,
											width: '90%',
											borderRadius: 30,
										}}
									/>
									<Button
										onClick={handleRegister}
										style={{
											background: '#13A8A6',
											width: '100%',
											textTransform: 'capitalize',
											color: '#fff',
											fontWeight: 'bold',
											marginTop: matches ? 20 : 50,
											fontSize: matches ? 12 : 16,
											padding: 10,
											borderRadius: 30,
										}}>
										Get Started
									</Button>
								</Box>
							</Grid>
							<Grid item xs={12} lg={1}></Grid>
							{/* <Grid item xs={12} lg={2}></Grid>
							<Grid item xs={12} lg={4}>
								<input
									type='text'
									placeholder='Enter Your Email'
									style={{
										border: '1px solid grey',
										padding: 12,
										width: '100%',
										borderRadius: 30,
									}}
								/>
							</Grid>
							<Grid item xs={12} lg={4}>
								<Box
									style={{
										background: '#0B4E59',
										color: 'white',
										padding: '12px',
										borderRadius: '30px',
										cursor: 'pointer',
										textAlign: 'center',
									}}>
									Get Free Access
								</Box>
							</Grid>
							<Grid item xs={12} lg={2}></Grid> */}
						</Grid>
					</Container>
				</div>
			</Container>
			{/* {matches ? '' : <Box className='bgst'></Box>} */}
		</>
	);
};

export default AIGenerated;
