import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select, Space } from 'antd';
import { otpVerifyDispatch } from '../reducers/HomeReducer';

const SignInSchema = Yup.object().shape({
	email: Yup.string().required('Email Required'),
	password: Yup.string().required('Password Required'),
});
const OTPForm = ({ SetStatus, handleClose, setOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [otp, setOtp] = useState(new Array(4).fill(''));
	const inputRefs = useRef([]);

	const handleChange = (element, index) => {
		const value = element.value;

		if (isNaN(value)) return; // Only allow numbers

		const newOtp = [...otp];
		newOtp[index] = value;

		setOtp(newOtp);

		// Focus the next input field
		if (value !== '' && index < 3) {
			inputRefs.current[index + 1].focus();
		}
	};
	const handleBackspace = (e, index) => {
		if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
			inputRefs.current[index - 1].focus();
		}
	};
	console.log('location', location);

	const handleSubmit = (e) => {
		// e.preventDefault();
		const otpValue = otp.join('');

		if (otpValue.length === 4) {
			// console.log('OTP Submitted:', otpValue);
			// alert(otpValue);
			let form = {
				email: location?.state?.email,
				otp: otpValue,
			};
			dispatch(otpVerifyDispatch(form, navigate));
			// navigate('/getstarted');
			// Submit OTP logic here
		} else {
			alert('Please enter a valid 6-digit OTP.');
		}
	};

	// const handleChange = (value) => {
	// 	console.log(`selected ${value}`);
	// };
	// const handleFormSubmit = (data) => {
	// 	alert(JSON.stringify(data));
	// };

	return (
		<Formik
			initialValues={{
				otp: '',
			}}
			// validationSchema={SignInSchema}
			onSubmit={handleSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box>
							<Box
								display={matches ? '' : 'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								ml={matches ? 2 : ''}
								mt={2}>
								<span
									style={{
										color: '#6c757d',
										textAlign: 'center',
									}}>
									Enter 4-digit code send to your email {''}
									{location?.state?.email}
								</span>
							</Box>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid
											container
											style={{
												padding: matches ? 0 : 15,
												marginTop: matches ? 10 : '',
											}}
											spacing={3}>
											<Grid item xs={12} style={{ textAlign: 'center' }}>
												{otp.map((_, index) => (
													<input
														style={{
															width: '40px',
															height: '40px',
															margin: '5px',
															textAlign: 'center',
															fontSize: '20px',
														}}
														key={index}
														type='text'
														maxLength='1'
														value={otp[index]}
														onChange={(e) => handleChange(e.target, index)}
														onKeyDown={(e) => handleBackspace(e, index)}
														ref={(el) => (inputRefs.current[index] = el)}
													/>
												))}
											</Grid>
											<Grid item xs={12}>
												<Button
													type='submit'
													style={{
														backgroundColor: '#185661',
														color: 'white',
														width: '100%',
														padding: 10,
														borderRadius: 10,
													}}>
													Submit
												</Button>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default OTPForm;
