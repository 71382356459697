import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

// Initial options for the PayPal script
const initialOptions = {
	clientId:
		'ARdxn_pX3LrtLd9CcV76nmdofvUcMSgb_eelRRaDLgnWiMpzKuLxtFu7SiSSXxysHaBaxJM0Tmv-ktG8',
	currency: 'USD',
};

const PayPalProvider = ({ children }) => {
	return (
		<PayPalScriptProvider options={initialOptions}>
			{children}
		</PayPalScriptProvider>
	);
};

export default PayPalProvider;
