import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Avatar,
	Container,
	useTheme,
	useMediaQuery,
	Grid,
} from '@mui/material';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/curelinea-gif-logo.gif';
import { Link, useNavigate } from 'react-router-dom';
import PayPalProvider from '../components/resuableComponents/PayPalProvider';
import PayPalButton from '../components/resuableComponents/PayPalButton';
import {
	categoryDispatch,
	mealplanDispatch,
	recipeplanDispatch,
	recipetitleGenerateSuccess,
	userLoginSuccess,
} from '../reducers/HomeReducer';
import Category from '../components/resuableComponents/Category';
import GeneralQuestionModal from '../components/resuableComponents/GeneralQuestionModal';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import PricingPlan from '../components/resuableComponents/PricingPlan';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';

const data = ['Beef', 'Chicken', 'Fish', 'Pork', 'Turkey', 'Vegetarian'];
const data2 = [
	{
		img: (
			<svg
				style={{ height: 35 }}
				class='h-6 w-6'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 20 20'
				fill='lightgrey'>
				<path d='m9.062 15.5-.833-.729q-2.354-2.104-4.041-4Q2.5 8.875 2.5 6.812q0-1.583 1.094-2.687 1.094-1.104 2.698-1.104.937 0 1.906.458.969.459 1.802 1.667.812-1.208 1.781-1.667.969-.458 1.907-.458 1.604 0 2.708 1.104T17.5 6.812q0 2.105-1.802 4.053-1.802 1.947-4.115 4.052l-.645.583q-.376.375-.928.365-.552-.011-.948-.365Z'></path>
			</svg>
		),
		name: 'Healthy',
	},
	{
		img: (
			<svg viewBox='0 0 24 24' fill='lightgrey' style={{ height: 35 }}>
				<path
					fill='lightgrey'
					d='M4 16.5V8H3V7h1V6H3V5h1V4H3V3h6c.5.03.95.24 1.34.63s.61.87.66 1.37h10v1H11c-.05.53-.27 1-.66 1.43S9.5 8.05 9 8H7v7.05c.05 0 .13-.01.24-.05s.2 0 .26 0c.56 0 1.2.14 1.92.42c.41-.92 1.11-1.74 2.11-2.42s2-1 3-1c1.53 0 2.83.53 3.89 1.59S20 15.95 20 17.5v.27c0 .13-.03.2-.03.23H3.14c.13-.5.41-1 .86-1.5m2-1.27V8H5v7.7c.5-.25.81-.4 1-.47M9 7V6H7v1h2m0-3H7v1h2V4M5 4v1h1V4H5m0 2v1h1V6H5M2 19h20l-2 2H4l-2-2Z'></path>
			</svg>
		),
		name: 'Hearty',
	},
	{
		img: (
			<svg viewBox='0 0 24 24' fill='lightgrey' style={{ height: 35 }}>
				<path
					fill='lightgrey'
					d='m5.44 7.96l.52-.53c.58-.58 1.54-.58 2.14.04l.02.03c.49.5 1.14.74 1.85.81c.97.09 1.91.61 2.53 1.55c.68 1.08.67 2.52-.04 3.59a3.322 3.322 0 0 1-5.18.55c-.56-.55-.88-1.26-.96-2c-.08-.73-.37-1.42-.88-1.93c-.58-.57-.58-1.53 0-2.11M9.64 16c-1.17 0-2.26-.45-3.07-1.28c-.7-.72-1.14-1.62-1.25-2.6c-.03-.3-.12-.69-.36-1.05C4.36 11.9 4 12.9 4 14c0 1.64.8 3.09 2.03 4H19v-1c0-3.6-2.39-6.65-5.66-7.65c.89 1.4.87 3.27-.04 4.65c-.8 1.25-2.18 2-3.66 2m5.14-8.44h1.27c.87 0 1.63.61 1.63 1.7V10h1.25V9c0-1.5-1.33-2.64-2.88-2.64h-1.27c-.83 0-1.54-.82-1.54-1.66s.71-1.46 1.54-1.46V2C13.24 2 12 3.24 12 4.78s1.24 2.78 2.78 2.78M4.5 7.55c.06-.1.14-.2.23-.3l.52-.52c.09-.09.19-.16.29-.23L4.13 5.07c.14-.27.09-.62-.13-.85a.767.767 0 0 0-1.07 0c-.14.14-.21.31-.22.49c-.18.01-.35.08-.49.22c-.29.29-.29.77 0 1.07c.23.22.57.27.85.13L4.5 7.55m13.89-3.16c.51-.51.83-1.2.83-1.97h-1.25c0 .83-.7 1.53-1.53 1.53v1.24c1.86 0 3.32 1.52 3.32 3.38V11H21V8.57a4.61 4.61 0 0 0-2.61-4.18M5 21h14c1.11 0 2-.89 2-2H3a2 2 0 0 0 2 2Z'></path>
			</svg>
		),
		name: 'Low Carb',
	},
	{
		img: (
			<svg viewBox='0 0 24 24' fill='lightgrey' style={{ height: 35 }}>
				<path
					fill='lightgrey'
					d='M20 10c2 3-3 12-5 12s-2-1-3-1s-1 1-3 1s-7-9-5-12s5-3 7-2V5C5.38 8.07 4.11 3.78 4.11 3.78S6.77.19 11 5V3h2v5c2-1 5-1 7 2Z'></path>
			</svg>
		),
		name: 'Whole Foods',
	},
];
const data3 = [
	'Select cuisine...',
	'American',
	'Asian',
	'Australian',
	'BBQ',
	'Belgian',
	'Brazilian',
	'British',
	'Caribbean',
	'Chinese',
	'Colombian',
	'Cuban',
	'Dominican',
	'Filipino',
	'French',
	'German',
	'Greek',
	'Hawaiian',
	'Indian',
	'Irish',
	'Italian',
	'Jamaican',
	'Japanese',
	'Korean',
	'Latin American',
	'Mediterranean',
	'Mexican',
	'Middle Eastern',
	'Mongolian',
	'Moroccan',
	'Persian',
	'Peruvian',
	'Southern',
	'Spanish',
	'Thai',
	'Vietnamese',
];

const Dashboard = () => {
	const { userLogin, loading, mealplan } = useSelector((state) => state.home);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [rec, setRec] = useState('');
	const [resp, setRec2] = useState('');
	const [rec3, setRec3] = useState('');
	const handleRec = (val) => {
		setRec(val);
	};
	const handleRec2 = (val) => {
		setRec2(val);
	};
	const handleRec3 = (val) => {
		setRec3(val);
	};
	console.log('rec3', rec3);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	useEffect(() => {
		dispatch(mealplanDispatch(userLogin?.result?.id));
		dispatch(recipeplanDispatch(userLogin?.result?.id));
	}, []);
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);
	const handleSelection = () => {
		const promt = `generate list of title for recipe ${rec} with ${resp} for ${rec3} take the userData and reply with the following in HTML format  (DO NOT REMOVE THE TAGS):->
  for Example:->

  <ul>
  <li> <recipe Title></li>
  <li> <recipe Title></li>
  <li> <recipe Title></li>
  and so on...
  </ul>`;
		navigate('/recipetitle', {
			state: { rec: rec, resp: resp, rec3: rec3, promt: promt },
		});
		dispatch(recipetitleGenerateSuccess(null));
	};
	useEffect(() => {
		dispatch(categoryDispatch());
	}, []);
	return (
		<>
			<MainHeader />
			<Container>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					style={{
						boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
						padding: 30,
						marginTop: 20,
						borderRadius: 12,
					}}>
					<Box>
						<h1>Dashboard</h1>
						<p>Welcome back! Here's a summary of your account.</p>
					</Box>
					<Box>
						<Button
							onClick={() => navigate('/profile')}
							style={{
								background: '#13A8A6',
								color: 'white',
								fontSize: 16,
								textTransform: 'capitalize',
							}}>
							View Profile
						</Button>
					</Box>
				</Box>
			</Container>
			<Box
				styl={{
					boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
					background: 'white',
				}}>
				<Category />
			</Box>
			<Container>
				<Box
					style={{
						// boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
						padding: 30,
						marginTop: 20,
						// borderRadius: 12,
					}}>
					<h1>Meal Plan</h1>
					<p>An overview of your weekly meal plans.</p>
					<Grid container spacing={matches ? 2 : 4}>
						{Array.from(Array(7)).map((i, index) => {
							return (
								<Grid item xs={6} lg={3}>
									<Box
										onClick={() =>
											mealplan?.result?.[index]
												? navigate('/response', {
														state: mealplan?.result?.[index],
												  })
												: ''
										}
										style={{
											boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
											padding: matches ? 10 : 30,
											marginTop: 20,
											borderRadius: 12,
											paddingBottom: 50,
											textAlign: 'center',
											fontSize: matches ? 20 : 26,
											fontWeight: 'bold',
											cursor: 'pointer',
										}}>
										Day {index + 1}
										<br></br>
										{mealplan?.result?.[index] ? (
											<svg
												style={{ marginTop: 20 }}
												focusable='false'
												color='inherit'
												fill='none'
												aria-hidden='true'
												role='presentation'
												viewBox='0 0 20 21'
												preserveAspectRatio='xMidYMid meet'
												width='24px'
												height='24px'
												class='sc-hMqMXs llihTZ'>
												<path
													d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
													fill='#319E37'></path>
											</svg>
										) : (
											<svg
												style={{ height: 25, marginTop: 20, color: 'red' }}
												class='fi-ta-icon-item fi-ta-icon-item-size-lg h-6 w-6 fi-color-custom text-custom-500 dark:text-custom-400 fi-color-danger'
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke-width='1.5'
												stroke='currentColor'
												aria-hidden='true'
												data-slot='icon'>
												<path
													stroke-linecap='round'
													stroke-linejoin='round'
													d='m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'></path>
											</svg>
										)}
									</Box>
								</Grid>
							);
						})}
					</Grid>
					{/* <MealTable /> */}
				</Box>
			</Container>
			<Container style={{ paddingBottom: 100, marginTop: 50 }}>
				{/* <Box
					style={{
						boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
						padding: 30,
						marginTop: 20,
						borderRadius: 12,
						paddingBottom: 50,
					}}> */}
				<PricingPlan />
				{/* </Box> */}
			</Container>
			<Container style={{ paddingBottom: 100 }}>
				<Box
					style={{
						boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
						padding: 30,
						marginTop: 20,
						borderRadius: 12,
						paddingBottom: 50,
					}}>
					{/* <p>An overview of your weekly recipe plans.</p> */}
					{/* <RecipeTable /> */}
					<Grid container spacing={4}>
						<Grid item xs={12} lg={4}>
							<Box>
								<img
									src='https://mealpractice.b-cdn.net/0/ai-recipe-generator.png'
									style={{ height: 200, borderRadius: 15 }}
								/>
							</Box>
							<h1 style={{ fontSize: 26 }}>Recipe Generator</h1>
							<p style={{ fontSize: 14 }}>
								Unleash your inner chef with our FREE AI-powered recipe
								generator! Save your favorite creations to your profile or
								effortlessly add them to your weekly meal plan, or send the
								ingredients straight to your grocery list!
							</p>
						</Grid>
						<Grid item xs={12} lg={8}>
							<Box
								style={{
									border: '1px solid lightgrey',
									padding: 25,
									borderRadius: 15,
								}}>
								<h1>Make your selections</h1>
								<p>
									Choose a protein, nutritional style, and cuisine to get
									started.
								</p>
								<Grid container spacing={2}>
									{data?.map((i) => {
										return (
											<Grid item xs={6} lg={2}>
												<Box
													onClick={() => handleRec(i)}
													style={{
														border:
															rec === i
																? '1px solid rgb(19, 168, 166)'
																: '1px solid lightgrey',
														padding: 15,
														borderRadius: matches ? '' : 5,
														textAlign: 'center',
														fontSize: 14,
														background:
															rec === i ? 'rgb(19 168 166 / 12%)' : '',
														cursor: 'pointer',
													}}>
													{i}
												</Box>
											</Grid>
										);
									})}
								</Grid>
								<Grid container style={{ marginTop: 20 }} spacing={2}>
									{data2.map((i) => {
										return (
											<Grid item xs={6} lg={3}>
												<div
													className='card3'
													onClick={() => handleRec2(i.name)}
													style={{
														border:
															resp === i.name
																? '1px solid rgb(19, 168, 166)'
																: '1px solid #e0e0e0',
														background:
															resp === i.name ? 'rgb(19 168 166 / 12%)' : '',
														cursor: 'pointer',
														borderRadius: matches ? '' : 5,
													}}>
													{i.img}
													<p>{i.name}</p>
												</div>
											</Grid>
										);
									})}
								</Grid>
								<select
									id='cuisine'
									name='cuisine'
									onChange={(e) => handleRec3(e.target.value)}
									style={{
										padding: 10,
										width: '100%',
										borderRadius: 12,
										marginTop: 20,
										border: '1px solid rgb(19, 168, 166)',
									}}>
									{data3?.map((i) => {
										return <option value={i}>{i}</option>;
									})}
								</select>
								<Grid container style={{ marginTop: 50 }}>
									<Grid item xs={1}></Grid>
									<Grid item xs={10}>
										<Button
											onClick={() => handleSelection()}
											disabled={rec && resp && rec3 ? false : true}
											style={{
												background:
													rec && resp && rec3
														? 'rgb(19, 168, 166)'
														: 'lightgrey',
												width: '100%',
												color: 'white',
												textTransform: 'capitalize',
												padding: 12,
												fontSize: 16,
												borderRadius: 50,
											}}>
											Make Selection
										</Button>
									</Grid>
									<Grid item xs={1}></Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
			{/* <GeneralQuestionModal /> */}
			<Box className='bgs'></Box>
			{loading ? <LoaderModal /> : ''}
			<Footer />
		</>
	);
};

export default Dashboard;
