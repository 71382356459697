import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import logo from '../assets/images/curelinea-gif-logo.gif';
import LoginForm from '../forms/LoginForm';
import { useNavigate } from 'react-router-dom';
import OTPForm from '../forms/OTPForm';
import { useSelector } from 'react-redux';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import Footer from '../components/resuableComponents/Footer';

const OtpPage = () => {
	const navigate = useNavigate();
	const { loading } = useSelector((state) => state.home);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div
			style={{
				background:
					'linear-gradient(to right top, rgb(129 217 191 / 82%), rgba(108, 112, 113, 0))',
				height: '100vh',
			}}>
			<Box style={{ paddingTop: 100, paddingBottom: 100 }}>
				<Grid container>
					<Grid item xs={1} lg={4}></Grid>
					<Grid item xs={10} lg={4}>
						<Box
							style={{
								background: '#fff',
								borderRadius: 10,
								padding: 20,
								boxShadow: 'rgb(129 217 191 / 33%) 0px 5px 15px',
							}}>
							<Box
								display={'flex'}
								justifyContent={'center'}
								onClick={() => navigate('/')}>
								<img
									src={logo}
									style={{
										height: matches ? '60px' : '100px',
										marginTop: 20,
										cursor: 'pointer',
									}}
								/>
							</Box>
							<OTPForm />
						</Box>
						<Box></Box>
					</Grid>
					<Grid item xs={1} lg={4}></Grid>
				</Grid>
			</Box>
			{loading ? <LoaderModal /> : ''}
		</div>
	);
};

export default OtpPage;
