import {
	Container,
	Box,
	Grid,
	useTheme,
	useMediaQuery,
	Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	OptionDispatch,
	OptionStatusDispatch,
	ResponseGenerateDispatch,
	updateactivitylevelDispatch,
	updatedietarypreferencesDispatch,
	updateGenderDispatch,
	updatemealspreferdayDispatch,
} from '../reducers/HomeReducer';
import arrow from '../assets/images/arrow-back.svg';
import { removeOpt, setOpt } from '../reducers/UiReducer';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import PayPalProvider from '../components/resuableComponents/PayPalProvider';
import PayPalButton from '../components/resuableComponents/PayPalButton';
import Stepper from '@mui/material/Stepper';
import mealplan from '../assets/images/Header.png';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Popover } from 'antd';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';
import user from '../assets/images/user.png';
import male from '../assets/images/general/male.png';
import female from '../assets/images/general/female.png';
import other from '../assets/images/general/other-gender.png';
import vegetarian from '../assets/images/general/vegetarian.png';
import nonvegetarian from '../assets/images/general/non-vegetarian.png';
import gluten from '../assets/images/general/gluten-free.png';
import dairy from '../assets/images/general/dairy-free.png';
import sedentary from '../assets/images/general/sedentary.png';
import very from '../assets/images/general/very-active.png';
import lightly from '../assets/images/general/lightly-active.png';
import moderately from '../assets/images/general/moderately-active.png';
import meals3 from '../assets/images/general/3-meals.png';
import meals4 from '../assets/images/general/4-meals.png';
import meals5 from '../assets/images/general/5-meals.png';
import vegan from '../assets/images/general/vegan.png';
import Icon from '../components/resuableComponents/Icon';

// import Paypal from '../components/resuableComponents/Paypal';
// import Pay from '../components/resuableComponents/Pay';
const data = [
	{
		ques: 'What is your gender?',
		opt: [
			{ title: 'Male', img: male },
			{ title: 'Female', img: female },
			{ title: 'Others', img: other },
		],
	},
	{
		ques: 'What is your activity level?',
		opt: [
			{ title: 'Sedentary (little or no exercise)', img: sedentary },
			{
				title: 'Lightly Active (light exercise/sports 1-3 days/week)',
				img: lightly,
			},
			{
				title: 'Moderately Active (moderate exercise/sports 3-5 days/week)',
				img: moderately,
			},
			{
				title: 'Very Active (hard exercise/sports 6-7 days a week)',
				img: very,
			},
		],
	},
	{
		ques: 'Do you have any dietary preferences?',
		opt: [
			{ title: 'Vegetarian', img: vegetarian },
			{
				title: 'Vegan',
				img: vegan,
			},
			{
				title: 'Non-Vegetarian',
				img: nonvegetarian,
			},
			{
				title: 'Gluten-Free',
				img: gluten,
			},
			{
				title: 'Dairy-Free',
				img: dairy,
			},
		],
	},
	{
		ques: 'How many meals do you prefer to have in a day?',
		opt: [
			{
				title: '3 meals (Breakfast, Lunch, Dinner)',
				img: meals3,
			},
			{
				title: '4 meals (Breakfast, Lunch, Snack, Dinner)',
				img: meals4,
			},
			{
				title:
					'5 meals (Breakfast, Mid-Morning Snack, Lunch, Evening Snack, Dinner)',
				img: meals5,
			},
		],
	},
	// {
	// 	ques: 'Do yoy have any foof allergies?',
	// 	opt: [
	// 		{
	// 			title: 'Gulten',
	// 			img: gluten,
	// 		},
	// 		{
	// 			title: 'Lactose',
	// 			img: gluten,
	// 		},
	// 		{
	// 			title: 'Eggs',
	// 			img: meals5,
	// 		},
	// 		{
	// 			title: 'Peanuts',
	// 			img: meals5,
	// 		},
	// 		{
	// 			title: 'Soy',
	// 			img: meals5,
	// 		},
	// 		{
	// 			title: 'Almonds',
	// 			img: meals5,
	// 		},
	// 		{
	// 			title: 'None',
	// 			img: meals5,
	// 		},
	// 	],
	// },
];

const GeneralQuestionPage = () => {
	const location = useLocation();
	const [step, setStep] = useState(0);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	const [checkout, setCheckOut] = useState(false);
	const { quest, option, loading, userLogin, loading2 } = useSelector(
		(state) => state.home
	);

	const steps = [
		'Select master blaster campaign settings',
		'Create an ad group',
		'Create an ad',
	];
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<p>{userLogin?.result?.mobile}</p>
			<p>Logout</p>
		</div>
	);
	// console.log('quest', quest?.question?.length);
	// console.log('step', location);
	return (
		<>
			<MainHeader />
			<Container>
				<Button
					variant='contained'
					color='primary'
					style={{ background: '#13A8A6', color: 'white', marginTop: 20 }}
					onClick={() => {
						// navigate(userLogin?.status === 'success' ? '/dashboard' : '/');
						if (step === 0) {
							navigate('/');
						} else {
							setStep(step - 1);
						}
					}}>
					Back
				</Button>
			</Container>
			<Container style={{ paddingBottom: 100 }}>
				<Box mt={2} sx={{ width: '100%' }}>
					<Stepper activeStep={step} alternativeLabel>
						{data?.map((label, index) => (
							<Step key={label.ques}>
								<StepLabel>{`Step ${index + 1}`}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
				{data?.map((i, index) => {
					return index === step ? (
						<>
							<h1
								style={{
									textAlign: 'center',
									fontSize: matches ? 26 : 36,
								}}>
								{i.ques}
							</h1>
							<Grid container>
								<Grid item xs={12} lg={4}></Grid>
								<Grid item xs={12} lg={4}>
									{i?.opt?.map((k) => {
										return (
											<Box
												className='card2'
												mt={2}
												onClick={() => {
													if (index === 0) {
														let form = {
															gender: k.title,
															email: userLogin?.result?.email,
														};
														dispatch(updateGenderDispatch(form, setStep, step));
													} else if (index === 1) {
														let form = {
															activitylevel: k.title,
															email: userLogin?.result?.email,
														};
														dispatch(
															updateactivitylevelDispatch(form, setStep, step)
														);
													} else if (index === 2) {
														let form = {
															dietarypreferences: k.title,
															email: userLogin?.result?.email,
														};
														dispatch(
															updatedietarypreferencesDispatch(
																form,
																setStep,
																step
															)
														);
													} else if (index === 3) {
														let form = {
															mealspreferday: k.title,
															email: userLogin?.result?.email,
														};
														dispatch(
															updatemealspreferdayDispatch(form, navigate)
														);
													}
												}}
												display={'flex'}
												alignItems={'center'}
												justifyContent={'space-between'}
												style={{
													padding: 20,
													border:
														step === 0
															? userLogin?.result?.gender === k.title
																? '2px solid rgb(11, 78, 89)'
																: '2px solid rgb(238, 238, 238)'
															: step === 1
															? userLogin?.result?.activitylevel === k.title
																? '2px solid rgb(11, 78, 89)'
																: '2px solid rgb(238, 238, 238)'
															: step === 2
															? userLogin?.result?.dietarypreferences ===
															  k.title
																? '2px solid rgb(11, 78, 89)'
																: '2px solid rgb(238, 238, 238)'
															: step === 3
															? userLogin?.result?.mealspreferday === k.title
																? '2px solid rgb(11, 78, 89)'
																: '2px solid rgb(238, 238, 238)'
															: '',

													borderRadius: 25,
													backgroundColor:
														step === 0
															? userLogin?.result?.gender === k.title
																? '#6fecd1b5'
																: ''
															: step === 1
															? userLogin?.result?.activitylevel === k.title
																? '#6fecd1b5'
																: ''
															: step === 2
															? userLogin?.result?.dietarypreferences ===
															  k.title
																? '#6fecd1b5'
																: ''
															: step === 3
															? userLogin?.result?.mealspreferday === k.title
																? '#6fecd1b5'
																: ''
															: '',
													cursor: 'pointer',

													boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
												}}>
												<Box display='flex' alignItems={'center'}>
													<img style={{ height: 60 }} src={k.img} />
													<span
														style={{
															fontSize: 20,
															// fontWeight: 'bold',
															marginLeft: 10,
														}}>
														{k.title}
													</span>
												</Box>
												{step === 0 ? (
													userLogin?.result?.gender === k.title ? (
														<Icon />
													) : (
														''
													)
												) : step === 1 ? (
													userLogin?.result?.activitylevel === k.title ? (
														<Icon />
													) : (
														''
													)
												) : step === 2 ? (
													userLogin?.result?.dietarypreferences === k.title ? (
														<Icon />
													) : (
														''
													)
												) : step === 3 ? (
													userLogin?.result?.mealspreferday === k.title ? (
														<Icon />
													) : (
														''
													)
												) : (
													''
												)}
											</Box>
										);
									})}
								</Grid>
								<Grid item xs={12} lg={4}></Grid>
							</Grid>
						</>
					) : (
						''
					);
				})}
			</Container>
			<Box className='bgs'></Box>
			{loading || loading2 ? <LoaderModal /> : ''}
		</>
	);
};

export default GeneralQuestionPage;
