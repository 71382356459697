import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select, Space } from 'antd';
import { updateUserDispatch, userLoginDispatch } from '../reducers/HomeReducer';

const SignInSchema = Yup.object().shape({
	name: Yup.string().required('Name Required'),
	password: Yup.string().required('Password Required'),
});
const FinishForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { loading } = useSelector((state) => state.home);
	const [tab, setTab] = useState('member');
	const handleTab = (val) => {
		setTab(val);
	};
	console.log('email', location);
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};
	const handleFormSubmit = (data) => {
		// alert(JSON.stringify(data));
		let form = {
			email: location.state.email,
			name: data.name,
			password: data.password,
		};
		dispatch(updateUserDispatch(form, navigate));
	};
	return (
		<Formik
			initialValues={{
				name: '',
				password: '',
			}}
			validationSchema={SignInSchema}
			onSubmit={handleFormSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid container style={{ padding: 15 }} spacing={3}>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Name <span style={{ color: 'red' }}>*</span>
												</span>
												<br></br>
												<Field
													name='name'
													type='text'
													placeHolder='Enter Your Name*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.name
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.name ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.name}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Create Password{' '}
													<span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='password'
													type='password'
													placeHolder='Enter Your Password*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.password
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.password ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.password}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<Button
													type='submit'
													style={{
														backgroundColor: '#185661',
														color: 'white',
														width: '100%',
														padding: 10,
														borderRadius: 10,
													}}>
													Complete Singup
												</Button>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default FinishForm;
