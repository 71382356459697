import React, { useEffect } from 'react';
import { Popover } from 'antd';
import {
	Container,
	Grid,
	Box,
	useTheme,
	useMediaQuery,
	Button,
	Avatar,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/curelinea-gif-logo.gif';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginSuccess } from '../reducers/HomeReducer';
import MainHeader from '../components/resuableComponents/MainHeader';
import PricingPlan from '../components/resuableComponents/PricingPlan';
import Footer from '../components/resuableComponents/Footer';
const PlanPage = () => {
	const navigate = useNavigate();
	const { userLogin } = useSelector((state) => state.home);
	const theme = useTheme();
	const dispatch = useDispatch();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);
	return (
		<>
			<MainHeader />
			<Container style={{ marginTop: 50, marginBottom: 50 }}>
				<PricingPlan />
			</Container>
			{/* <Footer /> */}
		</>
	);
};

export default PlanPage;
