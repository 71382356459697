import React, { useEffect } from 'react';
import MainHeader from '../components/resuableComponents/MainHeader';
import Category from '../components/resuableComponents/Category';
import { useDispatch } from 'react-redux';
import { categoryDispatch } from '../reducers/HomeReducer';
import { Box } from '@mui/material';

const CategoryPage = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(categoryDispatch());
	}, []);
	return (
		<>
			<MainHeader />
			<Category />
			<Box className='bgs'></Box>
		</>
	);
};

export default CategoryPage;
