import React, { useState } from 'react';
import {
	Grid,
	Box,
	Container,
	Button,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import logo from '../../assets/images/curelinea-gif-logo.gif';
import banner from '../../assets/images/banner-image-bg.jpg';
import mobile from '../../assets/images/mobile-ai-meal-plan.png';
import { Link, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { Popover } from 'antd';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import {
	userLoginSuccess,
	userRegisterDispatch,
} from '../../reducers/HomeReducer';

const Header = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userLogin } = useSelector((state) => state.home);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [email, setEmail] = useState('');
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate(
						userLogin?.result?.gender &&
							userLogin?.result?.activitylevel &&
							userLogin?.result?.dietarypreferences &&
							userLogin?.result?.mealspreferday
							? '/dashboard'
							: 'generalQuestion'
					);
				}}>
				Dashboard
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
				}}>
				Logout
			</Button>
		</div>
	);
	const handleRegister = () => {
		let form = {
			email: email,
		};
		dispatch(userRegisterDispatch(form, navigate));
	};
	return (
		<>
			<div style={{}}>
				<Container style={{ marginTop: 20 }}>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<Box>
							<img
								src={logo}
								style={{
									cursor: 'pointer',
									height: matches ? '40px' : '90px',
								}}
							/>
						</Box>
						<Box>
							{userLogin?.status === 'success' ? (
								<Popover content={content} title={`${userLogin?.result?.name}`}>
									<Avatar sx={{ bgcolor: '#13A8A6', cursor: 'pointer' }}>
										{userLogin?.result?.name.charAt(0)}
									</Avatar>
								</Popover>
							) : (
								<Link to='/login'>
									<Button
										style={{
											background: '#13A8A6',
											padding: matches ? '6px 10px' : '10px 30px',
											color: 'white',
											borderRadius: 25,
											textTransform: 'capitalize',
											alignItems: 'center',
											fontSize: matches ? 12 : '',
										}}>
										<PersonIcon
											style={{ marginLeft: 5, fontSize: matches ? 18 : '' }}
										/>
										Login
									</Button>
								</Link>
							)}
						</Box>
					</Box>
					<Grid container spacing={4} alignItems={'center'}>
						<Grid item xs={12} lg={6}>
							<Box
								mt={matches ? '0px' : '100px'}
								style={{
									textAlign: matches ? 'center' : '',
									paddingLeft: matches ? '' : 20,
									paddingRight: matches ? '' : 20,
								}}>
								<h1
									style={{
										fontSize: matches ? 54 : 72,
										fontWeight: 'bold',
										textShadow: '2px 2px 4px rgba(0,0,0,.3)',
										lineHeight: matches ? '62px' : '80px',
									}}>
									Customized Your{' '}
									<span style={{ color: 'rgb(11, 78, 89)' }}>FREE</span> AI
									Powered
								</h1>
								<span
									style={{
										fontSize: matches ? 26 : 50,
										color: '#13A8A6',
										fontWeight: 'bold',
										// lineHeight: matches ? '' : 0,
										textShadow: '2px 2px 4px #13A8A6',
									}}>
									Healthy Meal Plan !
								</span>
								<p style={{ fontSize: matches ? 14 : 20, marginTop: 20 }}>
									Unlock the secret to losing up to 10 lbs a month with our
									revolutionary AI-generated meal planner while enjoying
									delicious meals tailored just for you!.
								</p>
							</Box>
							<Grid
								container
								alignItems={'center'}
								style={{
									marginTop: matches ? 0 : 10,
									paddingLeft: 20,
									paddingRight: 20,
								}}
								spacing={matches ? 2 : 4}>
								<Grid item xs={12} lg={8}>
									<input
										onChange={(e) => setEmail(e.target.value)}
										type='text'
										placeholder='Enter Your Email'
										style={{
											border: '1px solid grey',
											padding: 12,
											width: '90%',
											borderRadius: 30,
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={4}>
									<Box
										onClick={handleRegister}
										style={{
											background: 'rgb(11, 78, 89)',
											color: 'white',
											padding: '12px',
											borderRadius: '30px',
											cursor: 'pointer',
											textAlign: 'center',
											fontSize: matches ? 12 : '',
										}}>
										Get Free Access
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6}>
							<img src={mobile} style={{ height: '100%', width: '100%' }} />
						</Grid>
					</Grid>
					<Box className='bgs'></Box>
					{/* <Box className='bgss'></Box> */}
				</Container>
			</div>
		</>
	);
};

export default Header;
