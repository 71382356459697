import React from 'react';
import { Grid, Box, Container, useMediaQuery, useTheme } from '@mui/material';
import signup from '../../assets/images/signup.png';
import servey from '../../assets/images/survey.png';
import recipe from '../../assets/images/recipe.png';
import ml from '../../assets/images/machine-learning.png';
import deply from '../../assets/images/deployment.png';
import fit from '../../assets/images/fit.png';

const data = [
	{
		img: signup,
		title: 'Sign Up',
		desc: 'Takes just seconds and costs you nothing.',
	},
	{
		img: servey,
		title: 'Answer Questions',
		desc: 'Simply answer a few questions. Your health goals, your favorite foods, any allergies or food restrictions.',
	},
	{
		img: recipe,
		title: 'Your FIRST Meal Plan',
		desc: 'Get your first customized meal plan tailored just minutes after you sign up.',
	},
	{
		img: ml,
		title: 'The AI Learns',
		desc: 'The more you use this incredible meal planning tool, the more the AI LEARNS. That means more meals that you’ll LOVE.',
	},
	{
		img: deply,
		title: 'Rapid Results',
		desc: 'Experience effective and sustainable fat loss with meal plans designed to optimize nutrition and boost metabolism.',
	},
	{
		img: fit,
		title: 'A NEW YOU!',
		desc: 'You’ll be eating so much delicious food, you will probably forget you’re also burning fat and getting healthier.',
	},
];
const ExpectSection = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<>
			<Container style={{}}>
				<h1
					style={{
						textAlign: 'center',
						fontWeight: 'bold',
						color: '#13A8A6',
						fontSize: 16,
					}}>
					Eat Better
				</h1>
				<h1
					style={{
						fontSize: matches ? 37 : 46,
						textAlign: 'center',
						fontWeight: 'bold',
						marginTop: 10,
						textShadow: '2px 2px 4px rgba(0,0,0,.3)',
					}}>
					Here’s what you can expect
				</h1>
				<Grid container style={{}}>
					<Grid item xs={12} lg={2}></Grid>
					<Grid item xs={12} lg={8}>
						<p style={{ textAlign: 'center', fontSize: matches ? 14 : 20 }}>
							Discover personalized recipe suggestions, organize your weekly
							meal plans, and shop for ingredients effortlessly. Get started now
							and transform your cooking experience!
						</p>
					</Grid>
					<Grid item xs={12} lg={2}></Grid>
				</Grid>
				<Grid container spacing={4}>
					{data.map((i, index) => {
						return (
							<Grid item xs={12} lg={4}>
								<Box
									className='card'
									textAlign={'center'}
									style={{
										boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
										// background: '#fff',
										padding: 20,
										borderRadius: 25,
										cursor: 'pointer',
									}}>
									<img
										src={i.img}
										style={{
											height: matches ? 40 : 70,
											boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
											background: '#fff',
											padding: 12,
											borderRadius: 15,
											// position: 'absolute',
											// bottom: index === 0 ? '-150%' : '',
											// left: index === 0 ? '19%' : '',
										}}
									/>
									{/* <br></br>
									<br></br>
									<br></br> */}
									<h1
										style={{ fontWeight: 'bold', fontSize: matches ? 14 : 24 }}>
										{i.title}
									</h1>
									<p style={{ fontSize: matches ? 9 : 14 }}>{i.desc}</p>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</Container>
			{matches ? '' : <Box className='bgss2'></Box>}
		</>
	);
};

export default ExpectSection;
