// App.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPaperPlane,
	faPaperclip,
	faFlag,
} from '@fortawesome/free-solid-svg-icons';

// Component to display each message
const ChatMessage = ({ sender, message, isBot }) => (
	<div className={`chat-message ${isBot ? 'bot-message' : 'user-message'}`}>
		<div className='message-header'>
			<span className='sender-name'>{sender}</span>
			{isBot && (
				<span className='report-issue'>
					<FontAwesomeIcon icon={faFlag} /> Report Issue
				</span>
			)}
		</div>
		<div className='message-content'>{message}</div>
	</div>
);

// Input component for sending messages
const MessageInput = ({ onSend }) => {
	const [message, setMessage] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		if (message.trim()) {
			onSend(message);
			setMessage('');
		}
	};

	return (
		<form className='message-input' onSubmit={handleSubmit}>
			<button className='attach-button'>
				<FontAwesomeIcon icon={faPaperclip} />
			</button>
			<input
				type='text'
				value={message}
				onChange={(e) => setMessage(e.target.value)}
				placeholder='Type your question here...'
			/>
			<button type='submit' className='send-button'>
				<FontAwesomeIcon icon={faPaperPlane} />
			</button>
		</form>
	);
};

// Main App component
function AIDoctor() {
	const [messages, setMessages] = useState([
		{
			sender: 'AI Doctor',
			message: 'Hello! How can I assist you?',
			isBot: true,
		},
		{ sender: 'Nitish', message: 'Hi', isBot: false },
	]);

	const handleSendMessage = (message) => {
		setMessages([...messages, { sender: 'User', message, isBot: false }]);
		// Simulate bot response
		setTimeout(() => {
			setMessages((prevMessages) => [
				...prevMessages,
				{ sender: 'AI Doctor', message: '...', isBot: true },
			]);
		}, 1000);
	};

	return (
		<div className='chat-container'>
			<div className='chatbox'>
				{messages.map((msg, index) => (
					<ChatMessage
						key={index}
						sender={msg.sender}
						message={msg.message}
						isBot={msg.isBot}
					/>
				))}
			</div>
			<MessageInput onSend={handleSendMessage} />
		</div>
	);
}

export default AIDoctor;
