import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser'; // Optional for rendering HTML directly
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {
	Box,
	Container,
	Button,
	Avatar,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import logo from '../assets/images/curelinea-gif-logo.gif';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import mealplan from '../assets/images/Header.png';
import { userLoginSuccess } from '../reducers/HomeReducer';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';

const ResponsePage = () => {
	const [meals, setMeals] = useState([]);
	const [totalNutrients, setTotalNutrients] = useState({});
	const [recipeLinks, setRecipeLinks] = useState([]);
	const { userLogin } = useSelector((state) => state.home);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	console.log('locat', location);
	console.log('meal', JSON.parse(location.state.content));
	console.log('totalNutrients', totalNutrients);
	console.log('recipeLinks', recipeLinks);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// const calories = JSON.parse(
	// 	location?.state?.content
	// )?.blocks[0]?.data?.reduce((accumulator, currentValue) => {
	// 	return accumulator.calories + currentValue.calories;
	// });
	const result = JSON.parse(location?.state?.content)?.blocks[0]?.data?.reduce(
		(acc, item) => {
			const calValue = parseInt(item.calories); // Convert 'cal' to integer
			const protValue = parseInt(item.protien); // Convert 'prot' to integer
			const carbsValue = parseInt(item.carbs); // Convert 'prot' to integer
			const fatValue = parseInt(item.fat); // Convert 'prot' to integer

			// Sum the values
			return {
				cal: acc.cal + calValue,
				prot: acc.prot + protValue,
				carbs: acc.carbs + carbsValue,
				fat: acc.fat + fatValue,
			};
		},
		{ cal: 0, prot: 0, carbs: 0, fat: 0 }
	); // Initial value is { cal: 0, prot: 0 }

	console.log(result); // Output: { cal: 100, prot: 100 }
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);
	console.log('calories', result);
	// useEffect(() => {
	// 	// This is the HTML string you provided
	// 	const htmlContent = location.state.content;

	// 	// Convert the string into a DOM element and extract meal info
	// 	const parser = new DOMParser();
	// 	const doc = parser.parseFromString(htmlContent, 'text/html');

	// 	// Extract meal plan data from the ordered list
	// 	const mealItems = doc.querySelectorAll('ol > li');
	// 	const mealData = Array.from(mealItems).map((mealItem) => {
	// 		const mealTitle = mealItem.textContent.split(': ')[1].trim();
	// 		const nutritionList = mealItem.nextElementSibling; // Next ul element

	// 		// Get nutrition info
	// 		const nutrients = Array.from(nutritionList.querySelectorAll('li')).reduce(
	// 			(acc, nutrient) => {
	// 				const [key, value] = nutrient.textContent
	// 					.split(':')
	// 					.map((item) => item.trim());
	// 				acc[key] = value;
	// 				return acc;
	// 			},
	// 			{}
	// 		);

	// 		return { meal: mealTitle, nutrition: nutrients };
	// 	});

	// 	// Extract total nutrient data
	// 	const totalNutrientList = doc.querySelector('h3 + ul');
	// 	const totalNutrients = Array.from(
	// 		totalNutrientList.querySelectorAll('li')
	// 	).reduce((acc, nutrient) => {
	// 		const [key, value] = nutrient.textContent
	// 			.split(':')
	// 			.map((item) => item.trim());
	// 		acc[key] = value;
	// 		return acc;
	// 	}, {});

	// 	// Extract recipe links

	// 	const recipeLinks = Array.from(doc.querySelectorAll('ul li a')).map(
	// 		(a) => ({
	// 			mealName: a.parentNode.textContent.split(': ')[0],
	// 			linkText: a.textContent,
	// 			link: a.getAttribute('href'),
	// 		})
	// 	);

	// 	setMeals(mealData);
	// 	setTotalNutrients(totalNutrients);
	// 	setRecipeLinks(recipeLinks);
	// }, []);

	return (
		<>
			<MainHeader />
			<Container>
				<div class='containermeal'>
					<div class='headermeal'>
						<h1 style={{ fontSize: matches ? 27 : '' }}>
							Your {location.state.days} Meals Plans
						</h1>
						{/* <a href='#' class='recipe-button'>
							Pay for your plan
						</a> */}
					</div>
					<Box>
						<img src={mealplan} style={{ width: '100%' }} />
					</Box>
					<div class='profile' style={{ display: matches ? '' : 'flex' }}>
						<div class='meal-profile'>
							<h3>The meal profile</h3>
							{/* <p>
								Aiming to <strong>Eat Healthy</strong> with an
								<strong>Anything diet</strong> and no allergies.
							</p> */}
						</div>
						<div class='daily-details'>
							<h3>The daily details</h3>
							<p>{`${result?.cal} Calories, ${result?.prot}g Protein, ${result?.carbs}g Carbs, ${result?.fat}g Fat`}</p>
						</div>
					</div>
					{JSON.parse(location?.state?.content)?.blocks[0]?.data?.map(
						(i, index) => {
							return (
								<div class='meal-card'>
									<Box
										display={'flex'}
										justifyContent={'space-between'}
										alignItems={'center'}>
										<h3 style={{ fontSize: matches ? 16 : '' }}>
											{i.mealname}
										</h3>
										<span
											style={{
												background: 'lightblue',
												padding: 5,
												fontSize: matches ? 12 : 14,
												borderRadius: 5,
												textAlign: 'center',
												fontWeight: 'bold',
											}}>
											{i.title} Calories
										</span>
									</Box>
									<div
										class='nutrition'
										style={{
											marginTop: 10,
											display: matches ? 'contents' : 'flex',
										}}>
										<span class='calories'>{i.calories} Calories</span>
										<span class='protein'>{i.protien} Protein</span>
										<span
											class='carbs'
											style={{ marginTop: matches ? 10 : '' }}>
											{i.carbs} Carbs
										</span>
										<span class='fat'>{i.fat} Fat</span>
									</div>
									<a
										href={i.link}
										class='recipe-button'
										target='_blank'
										style={{ marginTop: 10 }}>
										Recipe
									</a>
								</div>
							);
						}
					)}
					{/* <div class='meal-card'>
						<h3>Chicken Salad</h3>
						<p>
							<strong>Ingredients:</strong> 200g chicken breast, 250g mixed
							vegetables
						</p>
						<div class='nutrition'>
							<span class='calories'>365 Calories</span>
							<span class='protein'>30g Protein</span>
							<span class='carbs'>20g Carbs</span>
							<span class='fat'>15g Fat</span>
						</div>
						<a href='#' class='recipe-button'>
							Recipe
						</a>
					</div>
					<div class='meal-card'>
						<h3>Steamed Fish With Quinoa</h3>
						<p>
							<strong>Ingredients:</strong> 200g fish, 200g quinoa
						</p>
						<div class='nutrition'>
							<span class='calories'>520 Calories</span>
							<span class='protein'>40g Protein</span>
							<span class='carbs'>55g Carbs</span>
							<span class='fat'>10g Fat</span>
						</div>
						<a href='#' class='recipe-button'>
							Recipe
						</a>
					</div>
					<div class='meal-card'>
						<h3>Yogurt With Berries</h3>
						<p>
							<strong>Ingredients:</strong> 200g yogurt, 100g berries
						</p>
						<div class='nutrition'>
							<span class='calories'>200 Calories</span>
							<span class='protein'>11g Protein</span>
							<span class='carbs'>22g Carbs</span>
							<span class='fat'>8g Fat</span>
						</div>
						<a href='#' class='recipe-button'>
							Recipe
						</a>
					</div>
					<div class='meal-card'>
						<h3>Protein Shake</h3>
						<p>
							<strong>Ingredients:</strong> 30g protein powder, 300g water
						</p>
						<div class='nutrition'>
							<span class='calories'>173 Calories</span>
							<span class='protein'>20g Protein</span>
							<span class='carbs'>10g Carbs</span>
							<span class='fat'>5g Fat</span>
						</div>
						<a href='#' class='recipe-button'>
							Recipe
						</a>
					</div> */}
				</div>
			</Container>
			<Box className='bgs'></Box>
		</>
	);
};

export default ResponsePage;
