import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import Header from '../components/resuableComponents/Header';
import { Skeleton, Image, Divider } from 'antd'; // Assuming you are using Ant Design
import { Popover } from 'antd';
import {
	Container,
	Box,
	Avatar,
	Button,
	useTheme,
	useMediaQuery,
	Grid,
} from '@mui/material';
import logo from '../assets/images/curelinea-gif-logo.gif';
import {
	descGenerateDispatch,
	desGenerateDispatch,
	imageGenerateDispatch,
	recipeGenerateDispatch,
	userLoginSuccess,
} from '../reducers/HomeReducer';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';

function useQuery() {
	const { search } = useLocation();
	console.log('Search', search);
	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Recipe = () => {
	let query = useQuery();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { aiimage, loading, airecipe, desc, des } = useSelector(
		(state) => state.home
	);
	console.log(query.get('validationString'));
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { userLogin } = useSelector((state) => state.home);
	const String = query.get('validationString');
	useEffect(() => {
		if (query.get('validationString')) {
			let formData = {
				content: query.get('validationString'),
			};
			if (aiimage) {
			} else {
				const prompt = `Give the recipe, ingredients and calories for ${String} in the following format :->
    <h2>Recipe:</h2>
    <ul>
    <li><Instruction 1></li>
    <li><Instruction 2></li> and so on...
    </ul>
    <h2>Ingredients:</h2>
    <ul>
    <li><Ingredient 1></li>
    <li><Ingredient 2></li> and so on...
    </ul>
    <h2>Calories:</h2>
    <ul>
    <li><Calories of Ingredient 1></li>
    <li><Calories of Ingredient 2></li> and so on...
    </ul> 
    `;
				const prompt2 = `Give the recipe,without any instruction and without any description ingredients and calories for ${String} in the following format :->
    <h2>Fresh Produce:</h2>
    <ul>
    <li><Fresh Produce 1></li>
    <li><Fresh Produce 2></li> and so on...
    </ul>
    <h2>Meat and  Diary:</h2>
    <ul>
    <li><Meat and  Diary 1></li>
    <li><Meat and  Diary 2></li> and so on...
    </ul>
    <h2>Pantry Items:</h2>
    <ul>
    <li><Pantry Items 1></li>
    <li><Pantry Items 2></li> and so on...
    </ul> 
<h2>Species & Herbs:</h2>
    <ul>
    <li><Species & Herbs 1></li>
    <li><Species & Herbs 2></li> and so on...
    </ul> 
    `;
				let form = {
					content: prompt,
				};
				dispatch(imageGenerateDispatch(formData));
				dispatch(recipeGenerateDispatch(form));
				let desc = {
					content: `${String} small description`,
				};
				let form2 = {
					content: prompt2,
				};
				dispatch(descGenerateDispatch(desc));
				dispatch(desGenerateDispatch(form2));
			}
		}
	}, [query.get('validationString')]);
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);

	return (
		<>
			<MainHeader />
			<Container style={{ marginBottom: 20 }}>
				<p>Recipe / {String}</p>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={7}>
						{loading ? (
							<div className='skeleton-wrapper'>
								<div className='skeleton'></div>
							</div>
						) : (
							<img
								src={aiimage?.data[0]?.url}
								style={{ width: '100%', height: 450, borderRadius: 15 }}
							/>
						)}
					</Grid>
					<Grid item xs={12} lg={5}>
						<h1>{String}</h1>
						<div
							dangerouslySetInnerHTML={{
								__html: desc?.choices[0]?.message?.content.replace(
									/```html|```/g,
									''
								),
							}}
						/>
						<Button
							style={{
								marginTop: 50,
								background: loading ? 'rgb(11 78 89 / 50%)' : '#0b4e59',
								color: 'white',
								width: '100%',
								padding: 15,
								borderRadius: 35,
								fontSize: 16,
							}}>
							{loading ? (
								<>
									<CircularProgress color='inherit' />
									<span style={{ marginLeft: 5 }}>Please Wait...</span>
								</>
							) : (
								'Generated Recipe'
							)}
						</Button>
						{/* <div>{doc}</div> */}
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={4}>
					<Grid item xs={12} lg={6}>
						<div
							dangerouslySetInnerHTML={{
								__html: airecipe?.choices[0]?.message?.content?.replace(
									/```html|```/g,
									''
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} lg={1}>
						<div
							style={{
								borderLeft: '1px solid lightgrey',
								height: '100%',
							}}></div>
					</Grid>
					<Grid item xs={12} lg={5}>
						<div
							dangerouslySetInnerHTML={{
								__html: des?.choices[0]?.message?.content?.replace(
									/```html|```/g,
									''
								),
							}}
						/>
					</Grid>
				</Grid>
			</Container>
			{matches ? '' : <Box className='bgs'></Box>}
			{matches ? '' : <Box className='bgss'></Box>}
			{/* <Footer /> */}
		</>
	);
};

export default Recipe;
