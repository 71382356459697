import React, { useEffect } from 'react';
import Footer from '../components/resuableComponents/Footer';
import AIGenerated from '../components/resuableComponents/AIGenerated';
import FeedBack from '../components/resuableComponents/FeedBack';
import GetStarted from '../components/resuableComponents/GetStarted';
import AiDriven from '../components/resuableComponents/AiDriven';
import ExpectSection from '../components/resuableComponents/ExpectSection';
import Category from '../components/resuableComponents/Category';
import Header from '../components/resuableComponents/Header';
import { useDispatch, useSelector } from 'react-redux';
import {
	categoryDispatch,
	mealplanDispatch,
	optionSuccess,
	questSuccess,
	statusSuccess,
} from '../reducers/HomeReducer';
import { setModalStart, setOpt } from '../reducers/UiReducer';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import StartingModal from '../components/resuableComponents/StartingModal';

const HomePage = () => {
	const dispatch = useDispatch();
	const { loading, userLogin } = useSelector((state) => state.home);
	useEffect(() => {
		dispatch(categoryDispatch());
		dispatch(questSuccess(null));
		dispatch(optionSuccess(null));
		dispatch(statusSuccess(null));
		dispatch(setOpt(null));
		window.scrollTo(0, 0);
		setTimeout(() => {
			dispatch(setModalStart(true));
		}, 12000);
		dispatch(mealplanDispatch(userLogin?.result?.id));
	}, []);
	return (
		<>
			<Header />
			<Category />
			<ExpectSection />
			<AiDriven />
			<GetStarted />
			<FeedBack />
			<AIGenerated />
			{/* <StartingModal /> */}
			<Footer />
			{loading ? <LoaderModal /> : ''}
		</>
	);
};

export default HomePage;
