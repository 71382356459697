import React, { Fragment } from 'react';
import logo from '../../assets/images/Curelinea-gif-icon.gif';
import { Container, Box, useTheme, useMediaQuery } from '@mui/material';
import contact1 from '../../assets/images/contact1.svg';
import { Link } from 'react-router-dom';
import contact2 from '../../assets/images/contact2.svg';
import contact3 from '../../assets/images/contact3.svg';
import contact4 from '../../assets/images/contact4.svg';

const Footer = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Fragment>
			<div style={{ background: 'lightgrey', padding: 20 }}>
				<Container>
					<Box
						style={{ textAlign: 'center' }}
						display={matches ? '' : 'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<img src={logo} style={{ height: 70, borderRadius: 5 }} />
						<p style={{}}>© 2024 Curelinea All Rights Reserved.</p>
						<p>
							{'|'}{' '}
							<Link to='/privacypolicy'>
								<span style={{ marginLeft: 15 }}>Privacy Policy</span>
							</Link>{' '}
							{'|'}
							<Link to='/termandcondition'>
								<span style={{ marginLeft: 15 }}>Terms of Use</span>
							</Link>
						</p>
						<Box
							display={'flex'}
							alignItems={'center'}
							style={{
								// background: 'white',
								padding: 10,
								borderRadius: 5,
								// width: '100%',
							}}>
							<img
								src={contact1}
								style={{ height: matches ? 15 : 20, width: '100%' }}
							/>
							<img
								src={contact2}
								style={{
									height: matches ? 15 : 20,
									marginLeft: 10,
									width: '100%',
								}}
							/>
							<img
								src={contact3}
								style={{
									height: matches ? 15 : 20,
									marginLeft: 10,
									width: '100%',
								}}
							/>
							<img
								src={contact4}
								style={{
									height: matches ? 12 : 20,
									marginLeft: 10,
									width: '100%',
								}}
							/>
						</Box>
					</Box>
				</Container>
			</div>
		</Fragment>
	);
};

export default Footer;
