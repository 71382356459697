import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Select, Space } from 'antd';
import { userLoginDispatch } from '../reducers/HomeReducer';

const SignInSchema = Yup.object().shape({
	email: Yup.string().required('Email Required'),
	password: Yup.string().required('Password Required'),
});
const LoginForm = ({ SetStatus, handleClose, setOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { loading } = useSelector((state) => state.home);
	const [tab, setTab] = useState('member');
	const handleTab = (val) => {
		setTab(val);
	};
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};
	const handleFormSubmit = (data) => {
		// alert(JSON.stringify(data));
		dispatch(userLoginDispatch(data, navigate));
	};
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
			}}
			validationSchema={SignInSchema}
			onSubmit={handleFormSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box>
							<Box
								display={matches ? '' : 'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								ml={matches ? 2 : ''}
								mt={2}>
								<span
									style={{
										color: '#6c757d',
									}}>
									{/* What type of Customer? <span style={{ color: 'red' }}>*</span> */}
								</span>

								{/* <Box
									onClick={() => handleTab('member')}
									style={{
										backgroundColor: tab === 'member' ? '#0035F0' : '',
										color: tab === 'member' ? 'white' : '',
										padding: 10,
										borderRadius: 5,
										cursor: 'pointer',
									}}>
									Member
								</Box>
								<Box
									onClick={() => handleTab('agent')}
									style={{
										backgroundColor: tab === 'agent' ? '#0035F0' : '',
										color: tab === 'agent' ? 'white' : '',
										padding: 10,
										borderRadius: 5,
										cursor: 'pointer',
									}}>
									Agent
								</Box>
								<Box
									onClick={() => handleTab('admin')}
									style={{
										backgroundColor: tab === 'admin' ? '#0035F0' : '',
										color: tab === 'admin' ? 'white' : '',
										padding: 10,
										borderRadius: 5,
										cursor: 'pointer',
									}}>
									Admin
								</Box> */}
							</Box>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid container style={{ padding: 15 }} spacing={3}>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Email <span style={{ color: 'red' }}>*</span>
												</span>
												<br></br>
												<Field
													name='email'
													type='email'
													placeHolder='Enter Your Email*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.email
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.email ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.email}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Password <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='password'
													type='password'
													placeHolder='Enter Your Password*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.password
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.password ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.password}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<Button
													type='submit'
													style={{
														backgroundColor: '#185661',
														color: 'white',
														width: '100%',
														padding: 10,
														borderRadius: 10,
													}}>
													Login
												</Button>
												<p style={{ fontSize: 12, marginTop: 15 }}></p>
												<Box display={'flex'} alignItems={'center'} mt={2}>
													<p style={{ fontSize: 12 }}>Don't have an account?</p>
													<Link
														to='/register'
														style={{ fontSize: 12, marginLeft: 5 }}>
														Register
													</Link>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default LoginForm;
