import React, { useEffect, useState } from 'react';
import {
	Button,
	Box,
	Container,
	useTheme,
	useMediaQuery,
	Avatar,
} from '@mui/material';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Popover } from 'antd';
import '../ThankYou.css'; // Import external CSS for styling
import { useDispatch, useSelector } from 'react-redux';
import {
	bulkImageDispatch,
	mailDispatch,
	userLoginSuccess,
} from '../reducers/HomeReducer';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import logo from '../assets/images/curelinea-gif-logo.gif';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';

const ThankYouPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [meals, setMeals] = useState([]);
	const [totalNutrients, setTotalNutrients] = useState({});
	const [recipeLinks, setRecipeLinks] = useState([]);
	const { loading, userLogin } = useSelector((state) => state.home);

	const handleDashboardClick = () => {
		navigate('/dashboard');
	};

	const handleHomeClick = () => {
		navigate('/');
	};
	useEffect(() => {
		// This is the HTML string you provided
		const htmlContent = location?.state?.data?.result;

		// Convert the string into a DOM element and extract meal info
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlContent, 'text/html');

		// Extract meal plan data from the ordered list
		const mealItems = doc.querySelectorAll('ol > li');
		const mealData = Array.from(mealItems).map((mealItem) => {
			const mealTitle = mealItem.textContent.split(': ')[1].trim();
			const nutritionList = mealItem.nextElementSibling; // Next ul element

			// Get nutrition info
			const nutrients = Array.from(nutritionList.querySelectorAll('li')).reduce(
				(acc, nutrient) => {
					const [key, value] = nutrient.textContent
						.split(':')
						.map((item) => item.trim());
					acc[key] = value;
					return acc;
				},
				{}
			);

			return { meal: mealTitle, nutrition: nutrients };
		});

		// Extract total nutrient data
		const totalNutrientList = doc.querySelector('h3 + ul');
		const totalNutrients = Array.from(
			totalNutrientList.querySelectorAll('li')
		).reduce((acc, nutrient) => {
			const [key, value] = nutrient.textContent
				.split(':')
				.map((item) => item.trim());
			acc[key] = value;
			return acc;
		}, {});

		// Extract recipe links

		const recipeLinks = Array.from(doc.querySelectorAll('ul li a')).map(
			(a) => ({
				mealName: a.parentNode.textContent.split(': ')[0],
				linkText: a.textContent,
				link: a.getAttribute('href'),
			})
		);

		setMeals(mealData);
		setTotalNutrients(totalNutrients);
		setRecipeLinks(recipeLinks);
		if (mealData?.length > 0) {
			let form = {
				content1: mealData[0]?.meal,
				content2: mealData[1]?.meal,
				content3: mealData[2]?.meal,
				content4: mealData[3]?.meal,
				content5: mealData[4]?.meal,
			};
			dispatch(
				bulkImageDispatch(form, mealData, recipeLinks, location.state.body)
			);
		}
	}, []);
	console.log('locat', location);
	console.log('meal', meals);
	console.log('totalNutrients', totalNutrients);
	console.log('recipeLinks', recipeLinks);

	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);

	return (
		<>
			<MainHeader />
			<div className={loading ? '' : 'thank-you-container'}>
				{loading ? (
					<div className='container2'>
						<div className='loader'></div>
						<div className='message'>
							Please wait<span>...</span> We are Generating your FREE AI Meal
							Plan
						</div>
					</div>
				) : (
					<>
						<h1>Thank You for Trusting CureLinea!</h1>
						{/* <p>
							Your payment has been successfully processed, and we're excited to
							have you on board.
						</p> */}

						<h2>What's Next?</h2>
						<ul>
							<li>
								<strong>Access Your Personalized Health Plan:</strong> A
								confirmation email with your personalized health plan is on its
								way. Be sure to check your inbox!
							</li>
							<li>
								<strong>Stay Connected:</strong> You’ll receive regular updates,
								tips, and expert advice to guide you on your wellness journey.
							</li>
							<li>
								<strong>Have Questions?</strong> We're here for you! Feel free
								to reach out to our support team at{' '}
								<a href='mailto:support@curelinea.com'>support@curelinea.com</a>{' '}
								if you have any questions.
							</li>
						</ul>

						<p>Your Journey to Better Health Starts Now!</p>
						<p>
							Stay healthy,
							<br />
							The CureLinea Team
						</p>
						<iframe
							src='https://revenuedealer.o18.click/p?m=4883&t=f&gb=1'
							width='0px'
							height='0px'></iframe>

						<div className='button-container'>
							<button
								className='dashboard-button'
								onClick={handleDashboardClick}>
								Go to Dashboard
							</button>
							<button className='home-button' onClick={handleHomeClick}>
								Go to Home
							</button>
						</div>
					</>
				)}
			</div>
			<Box className='bgs'></Box>
			{/* {loading ? <LoaderModal /> : ''} */}
		</>
	);
};

export default ThankYouPage;
