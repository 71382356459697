import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileDispatch } from '../reducers/HomeReducer';
import { useNavigate } from 'react-router-dom';

const SignInSchema = Yup.object().shape({
	name: Yup.string().required('Name Required'),
	email: Yup.string().required('Email Required'),
	mobile: Yup.string().required('Mobile Required'),
	Address: Yup.string().required('Address Required'),
	City: Yup.string().required('City Required'),
	State: Yup.string().required('State Required'),
	Postal: Yup.string().required('Postal Required'),
	gender: Yup.string().required('Gender Required'),
});
const UpdateForm = ({ SetStatus, handleClose, setOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userLogin } = useSelector((state) => state.home);
	const [tab, setTab] = useState('member');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleTab = (val) => {
		setTab(val);
	};
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};
	const handleFormSubmit = (data) => {
		console.log('Data', data);
		let form = {
			id: userLogin?.result?.id,
			...data,
		};
		dispatch(updateProfileDispatch(form, navigate));
	};
	return (
		<Formik
			initialValues={{
				name: userLogin?.result?.name,
				email: userLogin?.result?.email,
				gender: userLogin?.result?.gender,
				mobile: userLogin?.result?.mobile,
				Address: userLogin?.result?.Address,
				City: userLogin?.result?.City,
				State: userLogin?.result?.State,
				Postal: userLogin?.result?.Postal,
			}}
			enableReinitialize={true}
			validationSchema={SignInSchema}
			onSubmit={handleFormSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box>
							{/*  */}
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid container style={{ padding: 15 }} spacing={3}>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Name
												</span>
												<Field
													name='name'
													type='text'
													placeHolder='Enter Your  Name*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.name
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.name ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.name}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Email
												</span>
												<Field
													name='email'
													type='email'
													placeHolder='Enter Your Email*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.email
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.email ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.email}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Phone Number
												</span>
												<Field
													name='mobile'
													type='number'
													placeHolder='Enter Your Mobile Number*'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.mobile
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.mobile ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.mobile}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Gender
												</span>
												<Field
													name='gender'
													type='text'
													placeHolder='Enter Your Gender'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.gender
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.gender ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.gender}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Address
												</span>
												<Field
													name='Address'
													type='text'
													placeHolder='Enter Your Address'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.Address
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.Address ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.Address}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													City
												</span>
												<Field
													name='City'
													type='text'
													placeHolder='Enter Your City'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.City
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.City ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.City}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													State
												</span>
												<Field
													name='State'
													type='text'
													placeHolder='Enter Your State'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.State
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.State ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.State}
													</span>
												) : null}
											</Grid>
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Postal
												</span>
												<Field
													name='Postal'
													type='text'
													placeHolder='Enter Your Postal'
													style={{
														padding: 10,
														width: '100%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.Postal
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.Postal ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.Postal}
													</span>
												) : null}
											</Grid>
											<Grid item xs={4}>
												<Button
													type='submit'
													style={{
														backgroundColor: 'rgb(19, 168, 166)',
														color: 'white',
														width: '100%',
														padding: 10,
													}}>
													Update
												</Button>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default UpdateForm;
