import React, { useEffect } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';
import { Container } from '@mui/material';

const PrivacyPolicyPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<MainHeader />
			<Container style={{ marginTop: 20, marginBottom: 20 }}>
				<div class='containerp'>
					<header>
						<h1>Privacy Policy</h1>
					</header>

					<section class='section'>
						{/* <h2>Privacy Policy</h2> */}
						<p>
							This privacy policy explains how we collect, use, and protect your
							personal information when you visit or interact with the Curelinea
							Meal Planner Website. By using our website, you agree to the
							collection and use of information as outlined in this policy.
						</p>
						<h3>1. Information We Collect</h3>
						<ul>
							<li>
								<strong>Personal Information:</strong> Name, email address, and
								contact details when you sign up for an account, subscribe to
								our newsletter, or contact us.
							</li>
							<li>
								<strong>Dietary Preferences & Meal History:</strong> Information
								related to your food preferences, dietary restrictions, and
								meals for customized meal planning.
							</li>
							<li>
								<strong>Usage Data:</strong> Information about your interaction
								with the website, including browsing history, page visits, and
								click actions.
							</li>
							<li>
								<strong>Device Information:</strong> Data such as your IP
								address, browser type, device type, operating system, and
								geolocation.
							</li>
						</ul>
						<h3>2. How We Use Your Information</h3>
						<ul>
							<li>
								Customization: Providing personalized meal suggestions and
								recommendations based on your preferences.
							</li>
							<li>
								Website Improvement: Analyzing user interactions to improve
								website functionality, content, and design.
							</li>
							<li>
								Communication: Sending newsletters, updates, promotions, and
								responding to your inquiries.
							</li>
							<li>
								Compliance & Security: Ensuring compliance with legal
								obligations, securing the website, and preventing fraudulent
								activity.
							</li>
						</ul>
						<h3>3. Third-Party Sharing</h3>
						<p>
							We may share your information with third parties in the following
							circumstances:
						</p>
						<ul>
							<li>
								Service Providers: Trusted third-party vendors who assist with
								hosting, data analysis, email services, and customer support.
							</li>
							<li>
								Analytics Providers: We use tools like Google Analytics to
								collect and analyze website traffic and usage patterns.
							</li>
							<li>
								Marketing Partners: With your consent, we may share your data
								with partners to send promotional offers or targeted
								advertising.
							</li>
						</ul>
						<p>We do not sell your personal information to third parties.</p>
						<h3>4. Cookies and Tracking Technologies</h3>
						<p>
							We use cookies and similar tracking technologies to enhance user
							experience, analyze website performance, and provide targeted
							advertisements. You can manage or disable cookies in your browser
							settings, but this may affect your ability to use certain features
							of the website.
						</p>
						<h3>5. Data Security</h3>
						<p>
							We prioritize protecting your data through industry-standard
							security measures, including:
						</p>
						<ul>
							<li>
								Encryption: We encrypt sensitive information during transmission
								using SSL (Secure Socket Layer) technology.
							</li>
							<li>
								Access Controls: Only authorized personnel can access your
								personal data, and we use multi-factor authentication for
								additional protection.
							</li>
							<li>
								Data Minimization: We collect only the data necessary to provide
								our services, and where possible, we anonymize or pseudonymize
								personal information.
							</li>
						</ul>
						<h3>6. Your Rights</h3>
						<p>
							As a user, you have the following rights concerning your personal
							data:
						</p>
						<ul>
							<li>
								Access & Rectification: You can request access to and correction
								of your personal information at any time.
							</li>
							<li>
								Deletion: You have the right to request the deletion of your
								personal information.
							</li>
							<li>
								Data Portability: You may request a copy of your personal data
								in a structured, commonly used format.
							</li>
							<li>
								Opt-Out of Marketing: You can unsubscribe from our marketing
								communications by clicking the "unsubscribe" link in our emails
								or contacting us directly.
							</li>
							<li>
								Cookie Management: You can manage your cookie preferences by
								adjusting your browser settings.
							</li>
						</ul>
						<h3>7. Data Retention</h3>
						<p>
							We retain personal information for as long as necessary to fulfill
							the purposes described in this privacy policy or as required by
							law. You can request deletion of your data at any time, subject to
							legal obligations.
						</p>
						<h3>8. International Data Transfers</h3>
						<p>
							If you are located outside of the country where our servers are
							hosted, your data may be transferred to, processed, and stored in
							a different country with different privacy laws. We ensure that
							adequate data protection measures are in place in such cases.
						</p>
						<h3>9. Children’s Privacy</h3>
						<p>
							The website is not intended for individuals under the age of 13,
							and we do not knowingly collect personal information from
							children. If you believe we have collected information from a
							child, please contact us to have it removed.
						</p>
						<h3>10. Changes to this Policy</h3>
						<p>
							We may update this privacy policy from time to time. Any changes
							will be posted on this page, and we may notify you via email or
							website announcements.
						</p>
						<h3>11. Contact Us</h3>
						<p>
							If you have any questions, concerns, or requests regarding this
							privacy policy or your personal data, please contact us at:
						</p>
						<p>Email: support@curelinea.com</p>
						{/* <h3>12. Governing Law</h3>
						<p>
							These Terms and your use of the Website are governed by and
							construed in accordance with the laws of Country, without regard
							to its conflict of law principles. Any legal
						</p>
						<h3>13. Severability</h3>
						<p>
							If any provision of these Terms is found to be invalid or
							unenforceable by a court of competent jurisdiction, the remaining
							provisions will continue in full force and effect.
						</p>
						<h3>14. Entire Agreement</h3>
						<p>
							These Terms, along with our Privacy Policy, Refund Policy, and any
							other legal notices posted on the Website, constitute the entire
							agreement between you and the Curelinearegarding the use of the
							Website.
						</p> */}
					</section>
				</div>
			</Container>
			<Footer />
		</>
	);
};

export default PrivacyPolicyPage;
