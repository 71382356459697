import React from 'react';
import { useNavigate } from 'react-router-dom';

const PricingPlan = () => {
	const navigate = useNavigate();
	return (
		<div class='pricing-table'>
			<table>
				<thead>
					<tr>
						<th></th>
						<th>Silver</th>
						<th>Gold</th>
						<th>Platinum</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Price as per</td>
						<td>
							<span class='highlight'>
								<s>$10</s> FREE
							</span>
						</td>
						<td>
							<span class='highlight'>
								<s>$20</s> $9
							</span>
						</td>
						<td>
							<span class='highlight'>
								<s>$35</s> $14
							</span>
						</td>
					</tr>
					<tr>
						<td>Meal Plans</td>
						<td>On Email</td>
						<td>On Email</td>
						<td>On Email</td>
					</tr>
					<tr>
						<td>Meal Plans Categories</td>
						<td>Single</td>
						<td>Multiple</td>
						<td>Multiple</td>
					</tr>
					<tr>
						<td>Dashboard Access</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>Health Tracker</td>
						<td>No</td>
						<td>Yes</td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>24/7 Virtual AI Nutritionist</td>
						<td>No</td>
						<td>No</td>
						<td>Yes</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td>
							<button
								class='btn-preorder'
								onClick={() => navigate('/payment', { state: 9 })}>
								Preorder
							</button>
						</td>
						<td>
							<button
								class='btn-preorder'
								onClick={() => navigate('/payment', { state: 14 })}>
								Preorder
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default PricingPlan;
