import {
	Container,
	Box,
	Grid,
	useTheme,
	useMediaQuery,
	Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	OptionDispatch,
	OptionStatusDispatch,
	ResponseGenerateDispatch,
} from '../reducers/HomeReducer';
import arrow from '../assets/images/arrow-back.svg';
import { removeOpt, setOpt } from '../reducers/UiReducer';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import PayPalProvider from '../components/resuableComponents/PayPalProvider';
import PayPalButton from '../components/resuableComponents/PayPalButton';
import Stepper from '@mui/material/Stepper';
import mealplan from '../assets/images/Header.png';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Popover } from 'antd';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';

// import Paypal from '../components/resuableComponents/Paypal';
// import Pay from '../components/resuableComponents/Pay';

const MealMentor = () => {
	const location = useLocation();
	const [step, setStep] = useState(0);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	const [checkout, setCheckOut] = useState(false);
	const { quest, option, loading, userLogin, loading2 } = useSelector(
		(state) => state.home
	);
	const appName = 'Curelinea';
	const { data, cat } = useSelector((state) => state.ui);
	console.log('data', data.join(''));
	let form = {
		name: userLogin?.result?.name,
		cat: cat,
		email: userLogin?.result?.email,
		content: `You are assistant of Curelinea. Answer the query of the user. Take the following information to give the answer to the user:->
  userData= for someone with ${data.join('')},and having gender=${
			userLogin?.result?.gender
		},activitylevel=${userLogin?.result?.activitylevel},dietarypreferences=${
			userLogin?.result?.dietarypreferences
		},mealspreferday=${
			userLogin?.result?.mealspreferday
		} avoiding any introduction about alcohol consumption and additional tips and tricks and notes, avoiding General Guidelines
  day=Monday
NOTE: The meal plan will vary according to the 'day' of the week. i.e for each day the meal plan will be different.
  ________________________________________________________________

  >take the userData and day and reply with the following in HTML format as per day of the week (DO NOT REMOVE THE TAGS):->
  for Example:->

  <ol>
  <li>Breakfast: <Meal Title></li>
  <ul>
  <li>Calories: in calories</li>
  <li>Protein: amount in gram</li>
  <li>Carbs: amount in gram</li>
  <li>Fat: amount in gram</li>
  </ul>

  <li>Snack: <Meal Title></li>
  <ul>
  <li>Calories: in calories</li>
  <li>Protein: amount in gram</li>
  <li>Carbs: amount in gram</li>
  <li>Fat: amount in gram</li>
  </ul>

  <li>Lunch: <Meal Title></li>
  <ul>
  <li>Calories: in calories</li>
  <li>Protein:amount in gram</li>
  <li>Carbs: amount in gram</li>
  <li>Fat: amount in gram</li>
  </ul>

  <li>Snack: <Meal Title></li>
  <ul>
  <li>Calories: in calories</li>
  <li>Protein: amount in gram</li>
  <li>Carbs: amount in gram</li>
  <li>Fat: amount in gram</li>
  </ul>

  <li>Dinner: <Meal Title></li>
  <ul>
  <li>Calories: in calories</li>
  <li>Protein: amount in gram</li>
  <li>Carbs: amount in gram</li>
  <li>Fat: amount in gram</li>
  </ul>
  </ol>  

  <h3>Total for the day:</h3>
  <ul>
  <li>Calories: amount</li>
  <li>Protein: amount in grams</li>
  <li>Carbs: amount in grams</li>
  <li>Fat: amount in grams</li>
  </ul>

  <h3>Recipe links:</h3>
  <ul>
  <li>Breakfast: <a href="https://${appName}.com/recipe?validationString=<Meal Title 'seperated by -' >"><Meal Title></a></li>
  <li>Snack: <a href="https://${appName}.com/recipe?validationString=<Meal Title 'seperated by -' >"><Meal Title></a></li>
  <li>Lunch: <a href="https://${appName}.com/recipe?validationString=<Meal Title 'seperated by -' >"><Meal Title></a></li>
  <li>Snack: <a href="https://${appName}.com/recipe?validationString=<Meal Title 'seperated by -' >"><Meal Title></a></li>
  <li>Dinner: <a href="https://${appName}.com/recipe?validationString=<Meal Title 'seperated by -' >"><Meal Title></a></li>
  </ul>
  

  Enjoy your meals!

  > For other queries reply with the following in HTML format:->
  <h3><--reply here--></h3>
`,
	};
	// const handleRes = () => {

	// 	// Provide a meal plan for someone with ,Do you have any known liver conditions?-Fatty Liver,How often do you consume alcohol?-Daily, only show meal plan with Recipe link avoiding any introduction about alcohol consumption and additional tips and tricks and
	// };
	useEffect(() => {
		// dispatch(location.state.id);
		if (quest?.question?.length > 0) {
			dispatch(OptionDispatch(quest?.question[step].id));
		}
		// if (option?.question?.length > 0) {
		// 	let val = option?.question?.filter(
		// 		(person) => person.status === 'active'
		// 	);
		// 	if (val?.length > 0) {
		// 		dispatch(
		// 			OptionStatusDispatch(val[0].id, 'inactive', quest?.question[step].id)
		// 		);
		// 	}
		// }
	}, [quest, step]);
	// useEffect(() => {
	// 	let val = option?.question?.filter((person) => person.status === 'active');
	// 	if (val?.length > 0) {
	// 		dispatch(
	// 			OptionStatusDispatch(val[0].id, 'inactive', quest?.question[step].id)
	// 		);
	// 	}
	// }, [quest]);
	const steps = [
		'Select master blaster campaign settings',
		'Create an ad group',
		'Create an ad',
	];
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<p>{userLogin?.result?.mobile}</p>
			<p>Logout</p>
		</div>
	);
	console.log('quest', quest?.question?.length);
	console.log('step', location);
	return (
		<>
			<MainHeader />
			<Container style={{ paddingBottom: 100 }}>
				<Box mt={2} sx={{ width: '100%' }}>
					<Stepper activeStep={step} alternativeLabel>
						{quest?.question?.map((label, index) => (
							<Step key={label.name}>
								<StepLabel>{`Step ${index + 1}`}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
				{checkout ? (
					quest?.question?.length === step + 1 ? (
						<Grid container style={{ marginTop: 50 }}>
							<Grid item xs={12} lg={4}></Grid>
							<Grid item xs={12} lg={4}>
								{/* <PayPalProvider>
									<h1 style={{ fontSize: matches ? 26 : '' }}>
										Pay with PayPal $1
									</h1>
									<PayPalButton form={form} />
								</PayPalProvider> */}
								<Button>Submit</Button>
							</Grid>
							<Grid item xs={12} lg={4}></Grid>
						</Grid>
					) : (
						''
					)
				) : (
					quest?.question?.map((i, index) => {
						return index === step ? (
							<>
								<h1
									style={{
										textAlign: 'center',
										fontSize: matches ? 26 : 36,
									}}>
									{i.name}
								</h1>
								<Grid container>
									<Grid item xs={12} lg={4}></Grid>
									<Grid item xs={12} lg={4}>
										{option?.question?.map((k) => {
											return (
												<Box
													className='card2'
													mt={2}
													onClick={() => {
														let val = option?.question?.filter(
															(person) => person.status === 'active'
														);
														console.log('first', val);

														if (val?.length > 0) {
															if (val[0].id === k.id) {
																dispatch(
																	OptionStatusDispatch(
																		val[0].id,
																		'active',
																		quest?.question[step].id,
																		quest?.question?.length === step + 1
																	)
																);
																if (quest?.question?.length === step + 1) {
																	dispatch(
																		ResponseGenerateDispatch(
																			form,
																			navigate,
																			data
																		)
																	);
																} else {
																	setStep(step + 1);
																}
																// dispatch(removeOpt(val[0].option));
															} else {
																dispatch(
																	OptionStatusDispatch(
																		val[0].id,
																		'inactive',
																		quest?.question[step].id,
																		quest?.question?.length === step + 1
																	)
																);
																dispatch(
																	OptionStatusDispatch(
																		k.id,
																		'active',
																		quest?.question[step].id,
																		quest?.question?.length === step + 1
																		// setStep(),
																		// step
																	)
																);
																dispatch(removeOpt(val[0].option));
																dispatch(setOpt(`${i.name}-${k.option}`));
																if (quest?.question?.length === step + 1) {
																} else {
																	setStep(step + 1);
																}
															}
														} else {
															dispatch(
																OptionStatusDispatch(
																	k.id,
																	'active',
																	quest?.question[step].id,
																	quest?.question?.length === step + 1
																	// setStep(),
																	// step
																)
															);
															dispatch(setOpt(`${i.name}-${k.option}`));
															if (quest?.question?.length === step + 1) {
																// setCheckOut(true);
																dispatch(
																	ResponseGenerateDispatch(form, navigate, data)
																);
															} else {
																setStep(step + 1);
															}
														}
														console.log('val', val);
													}}
													display={'flex'}
													alignItems={'center'}
													justifyContent={'space-between'}
													style={{
														padding: 20,
														border:
															k.status === 'active'
																? '2px solid rgb(11, 78, 89)'
																: '2px solid rgb(238, 238, 238)',
														borderRadius: 25,
														backgroundColor:
															k.status === 'active' ? '#6fecd1b5' : '',
														cursor: 'pointer',

														boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
													}}>
													<Box display='flex' alignItems={'center'}>
														<img style={{ height: 60 }} src={k.imgpath} />
														<span
															style={{
																fontSize: 20,
																fontWeight: 'bold',
																marginLeft: 10,
															}}>
															{k.option}
														</span>
													</Box>
													{k.status === 'active' ? (
														<svg
															focusable='false'
															color='inherit'
															fill='none'
															aria-hidden='true'
															role='presentation'
															viewBox='0 0 20 21'
															preserveAspectRatio='xMidYMid meet'
															width='24px'
															height='24px'
															class='sc-hMqMXs llihTZ'>
															<path
																d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																fill='rgb(11, 78, 89)'></path>
														</svg>
													) : (
														''
													)}
												</Box>
											);
										})}
									</Grid>
									<Grid item xs={12} lg={4}></Grid>
								</Grid>
							</>
						) : (
							''
						);
					})
				)}
			</Container>
			{loading || loading2 ? <LoaderModal /> : ''}
		</>
	);
};

export default MealMentor;
