import React, { useState } from 'react';
import { Grid, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import nutri from '../../assets/images/nutrition.jpg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AiDriven = () => {
	const [step, setStep] = useState('one');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleStep = (val) => {
		setStep(val);
	};
	return (
		<>
			<Container style={{ marginTop: matches ? 50 : 150, marginBottom: 50 }}>
				<h1
					style={{
						textAlign: 'center',
						fontSize: matches ? 26 : 46,
						fontWeight: 'bold',
						textShadow: '2px 2px 4px rgba(0,0,0,.3)',
					}}>
					Ai-Driven Meal Plans Are Transforming How People Eat, Look And Feel
				</h1>
				<Grid container spacing={8} style={{ marginTop: matches ? 0 : 50 }}>
					<Grid item xs={12} lg={6}>
						<img
							src={nutri}
							style={{
								width: '100%',
								background: 'white',
								padding: matches ? '' : 10,
								borderRadius: 25,
								textAlign: 'center',
								cursor: 'pointer',
								boxShadow: 'rgb(19, 168, 166) 0.5rem 0.4rem, 0.0rem 0rem',
							}}
						/>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Box
							onClick={() => handleStep('one')}
							style={{
								padding: 12,
								border: '1px solid grey',
								fontSize: 20,
								borderRadius: 10,
								background: step === 'one' ? '' : 'rgb(19, 168, 166)',
								color: step === 'one' ? '' : 'white',
								cursor: 'pointer',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
							mt={2}>
							<span>Its designed around you</span>{' '}
							{step === 'one' ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</Box>
						{step === 'one' && (
							<p style={{ marginLeft: 10 }}>
								Discover personalized recipe suggestions, organize your weekly
								meal plans, and shop for ingredients effortlessly. Get started
								now and transform your cooking experience!
							</p>
						)}
						<Box
							onClick={() => handleStep('two')}
							style={{
								padding: 12,
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								border: '1px solid grey',
								fontSize: 20,
								borderRadius: 10,
								background: step === 'two' ? '' : 'rgb(19, 168, 166)',
								color: step === 'two' ? '' : 'white',
								cursor: 'pointer',
							}}
							mt={2}>
							<span>Its Simple Work</span>{' '}
							{step === 'two' ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</Box>
						{step === 'two' && (
							<p style={{ marginLeft: 10 }}>
								Discover personalized recipe suggestions, organize your weekly
								meal plans, and shop for ingredients effortlessly. Get started
								now and transform your cooking experience!
							</p>
						)}
						<Box
							onClick={() => handleStep('three')}
							style={{
								padding: 12,
								border: '1px solid grey',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								fontSize: 20,
								borderRadius: 10,
								background: step === 'three' ? '' : 'rgb(19, 168, 166)',
								color: step === 'three' ? '' : 'white',
								cursor: 'pointer',
							}}
							mt={2}>
							<span>Its fast and free !</span>{' '}
							{step === 'three' ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</Box>
						{step === 'three' && (
							<p style={{ marginLeft: 10 }}>
								Discover personalized recipe suggestions, organize your weekly
								meal plans, and shop for ingredients effortlessly. Get started
								now and transform your cooking experience!
							</p>
						)}
						<Box
							onClick={() => handleStep('four')}
							style={{
								padding: 12,
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								border: '1px solid grey',
								fontSize: 20,
								borderRadius: 10,
								background: step === 'four' ? '' : 'rgb(19, 168, 166)',
								color: step === 'four' ? '' : 'white',
								cursor: 'pointer',
							}}
							mt={2}>
							<span>24x7 Availability</span>{' '}
							{step === 'four' ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</Box>
						{step === 'four' && (
							<p style={{ marginLeft: 10 }}>
								Discover personalized recipe suggestions, organize your weekly
								meal plans, and shop for ingredients effortlessly. Get started
								now and transform your cooking experience!
							</p>
						)}
					</Grid>
				</Grid>
				{matches ? '' : <Box className='bgss3'></Box>}
			</Container>
		</>
	);
};

export default AiDriven;
