import { createSlice } from '@reduxjs/toolkit';

export const storeData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		sessionStorage.setItem(`${key}`, jsonValue);
	} catch (e) {
		// saving error
	}
};

export const getData = async (key) => {
	try {
		const jsonValue = sessionStorage.getItem(`${key}`);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
	}
};
const UiReducer = createSlice({
	name: 'ui',
	initialState: {
		tab: 'men',
		data: [],
		modal: false,
		modalStart: false,
		cat: sessionStorage.getItem('cat')
			? JSON.parse(sessionStorage.getItem('cat'))
			: null,
		upgrade: false,
	},
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		setUpgrade(state, action) {
			state.upgrade = action.payload;
		},
		setCateg(state, action) {
			state.cat = action.payload;
			storeData('cat', action.payload);
		},
		setRec(state, action) {
			state.recp = action.payload;
		},
		setModal(state, action) {
			state.modal = action.payload;
		},
		setModalStart(state, action) {
			state.modalStart = action.payload;
		},
		setOpt(state, action) {
			if (action.payload === null) {
				state.data = [];
			} else {
				if (state.data.includes(action.payload)) {
				} else {
					state.data.push(`,${action.payload}`);
				}
			}
		},
		removeOpt(state, action) {
			// let newArray = state.data.filter((item) => item !== action.payload);
			let substringToRemove = `,${action.payload}`;
			let newArray = state.data.map((item) =>
				item.replace(substringToRemove, '')
			);

			state.data = newArray;
		},
	},
});

const { actions } = UiReducer;

export const {
	setTab,
	setOpt,
	removeOpt,
	setModal,
	setModalStart,
	setCateg,
	setUpgrade,
} = actions;

export default UiReducer;
