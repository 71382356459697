import React from 'react';
import { Grid, Container, Box, useTheme, useMediaQuery } from '@mui/material';

const FeedBack = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<>
			<div
				style={{
					background: '#d5fafc',
					marginTop: 50,
					padding: matches ? '' : 50,
					paddingBottom: matches ? 60 : 50,
				}}>
				<Container>
					<Grid container spacing={4}>
						<Grid item xs={12} lg={6}>
							<Box display={'flex'} alignItems={'center'}>
								{matches ? (
									''
								) : (
									<img src='https://osmooai.com/images/quotesBackground.svg' />
								)}
								<h1
									style={{
										fontSize: matches ? 34 : 46,
										fontWeight: 'bold',
										marginLeft: 20,
										textShadow: '2px 2px 4px rgba(0,0,0,.3)',
										lineHeight: matches ? '34px' : '',
									}}>
									Here are what some of our users are saying
								</h1>
							</Box>
							<Grid container>
								<Grid item xs={12} lg={4}></Grid>
								<Grid item xs={12} lg={8}>
									<Box
										display={'flex'}
										style={{
											background: 'white',
											padding: 20,
											boxShadow: '0 8px 12px rgba(0,0,0,.1)',
											borderRadius: 5,
										}}>
										<img
											src='https://osmooai.com/images/quotesBackground.svg'
											style={{ height: 20 }}
										/>
										<Box>
											<p style={{ marginLeft: 20 }}>
												I can't believe how incredible taastee meal plans are!
												In just a few weeks, I've seen a noticeable difference
												in my weight and energy levels. The personalized meal
												plans make it so easy to stick to my goals, and the
												variety of delicious recipes keeps me excited for every
												meal.
											</p>
											<p style={{ marginLeft: 20, fontWeight: 'bold' }}>
												SAMANTHA J
											</p>
											<p
												style={{
													marginLeft: 20,
													// fontWeight: 'bold',
													lineHeight: 0,
												}}>
												Diet Planner
											</p>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Grid container>
								<Grid item xs={12} lg={11}>
									<Box
										display={'flex'}
										style={{
											background: 'white',
											padding: 20,
											boxShadow: '0 8px 12px rgba(0,0,0,.1)',
											borderRadius: 5,
										}}>
										<img
											src='https://osmooai.com/images/quotesBackground.svg'
											style={{ height: 20 }}
										/>
										<Box>
											<p style={{ marginLeft: 20 }}>
												I am blown away by the power and effectiveness of
												taastee's AI-generated meal plans. The personalized
												approach has made it so much easier for me to stay on
												track and reach my weight loss goals. The grocery lists
												and diverse recipes save me so much time and effort, and
												I love that the meal plans adapt as I progress on my
												fat-burning journey. taastee has made healthy eating an
												absolute pleasure, and I can't recommend it enough!
											</p>
											<p style={{ marginLeft: 20, fontWeight: 'bold' }}>
												JENNIFER LAWRENCE
											</p>
											<p
												style={{
													marginLeft: 20,
													// fontWeight: 'bold',
													lineHeight: 0,
												}}>
												Diet Planner
											</p>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} lg={1}></Grid>
							</Grid>
							<Grid container>
								<Grid item xs={12} lg={8}>
									<Box
										mt={2}
										display={'flex'}
										style={{
											background: 'white',
											padding: 20,
											boxShadow: '0 8px 12px rgba(0,0,0,.1)',
											borderRadius: 5,
										}}>
										<img
											src='https://osmooai.com/images/quotesBackground.svg'
											style={{ height: 20 }}
										/>
										<Box>
											<p style={{ marginLeft: 20 }}>
												Curelinea free, AI-driven fat-burning meal plans have
												been a game-changer for me. I've tried countless diets
												and meal plans in the past, but nothing has worked as
												effectively as curelinea.
											</p>
											<p style={{ marginLeft: 20, fontWeight: 'bold' }}>
												MICHAEL ROCH
											</p>
											<p
												style={{
													marginLeft: 20,
													// fontWeight: 'bold',
													lineHeight: 0,
												}}>
												Diet Planner
											</p>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} lg={4}></Grid>
							</Grid>
						</Grid>
					</Grid>

					{/* <Grid container style={{ marginTop: 50 }} spacing={2}>
						{Array.from(Array(6)).map((i) => {
							return (
								<Grid item xs={12} lg={4}>
									<Box
										display={'flex'}
										style={{
											background: 'white',
											padding: 20,
											boxShadow: '0 8px 12px rgba(0,0,0,.1)',
											borderRadius: 5,
										}}>
										<img
											src='https://osmooai.com/images/quotesBackground.svg'
											style={{ height: 20 }}
										/>
										<Box>
											<p style={{ marginLeft: 20 }}>
												I am blown away by the power and effectiveness of
												taastee's AI-generated meal plans. The personalized
												approach has made it so much easier for me to stay on
												track and reach my weight loss goals. The grocery lists
												and diverse recipes save me so much time and effort, and
												I love that the meal plans adapt as I progress on my
												fat-burning journey. taastee has made healthy eating an
												absolute pleasure, and I can't recommend it enough!
											</p>
											<p style={{ marginLeft: 20, fontWeight: 'bold' }}>
												JENNIFER LAWRENCE
											</p>
										</Box>
									</Box>
								</Grid>
							);
						})}
					</Grid> */}
				</Container>
			</div>
		</>
	);
};

export default FeedBack;
