import React, { useEffect } from 'react';
import UpdateForm from '../forms/UpdateForm';
import { Box, Container, Grid, Button } from '@mui/material';
import logo from '../assets/images/curelinea-gif-logo.gif';
import MainHeader from '../components/resuableComponents/MainHeader';
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoaderModal from '../components/resuableComponents/LoaderModal';

const UpdatePage = () => {
	const { userLogin, loading } = useSelector((state) => state.home);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<MainHeader />
			<Container>
				<p>Dashboard / Profile</p>
				<Grid container spacing={4} style={{ marginTop: 20, marginBottom: 50 }}>
					<Grid item xs={12} lg={5}>
						<div class='profile-card'>
							<Box display={'flex'} justifyContent={'center'}>
								<AccountCircleIcon style={{ fontSize: 100 }} />
							</Box>
							<h2>{userLogin?.result?.name}</h2>
							<div class='details'>
								<h3>Personal Information</h3>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>Name</Box>
									<Box style={{ color: 'grey' }}>{userLogin?.result?.name}</Box>
								</Box>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>Email</Box>
									<Box style={{ color: 'grey' }}>
										{userLogin?.result?.email}
									</Box>
								</Box>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>Gender</Box>
									<Box style={{ color: 'grey' }}>
										{userLogin?.result?.gender}
									</Box>
								</Box>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>Phone</Box>
									<Box style={{ color: 'grey' }}>
										{userLogin?.result?.mobile}
									</Box>
								</Box>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>Address</Box>
									<Box style={{ color: 'grey' }}>
										{userLogin?.result?.Address}
									</Box>
								</Box>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>City</Box>
									<Box style={{ color: 'grey' }}>{userLogin?.result?.City}</Box>
								</Box>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>State</Box>
									<Box style={{ color: 'grey' }}>
										{userLogin?.result?.State}
									</Box>
								</Box>
								<Box display={'flex'} justifyContent={'space-between'} mt={2}>
									<Box style={{ fontWeight: 'bold' }}>Status</Box>
									<Box style={{ color: 'grey' }}>
										{userLogin?.result?.status === 'active'
											? 'Subscribe'
											: 'UnSubscribe'}
									</Box>
								</Box>
								<Box
									display={'flex'}
									justifyContent={'space-between'}
									mt={2}
									mb={4}>
									<Box style={{ fontWeight: 'bold' }}>Postal</Box>
									<Box style={{ color: 'grey' }}>
										{userLogin?.result?.Postal}
									</Box>
								</Box>
							</div>
						</div>
						{/* </Box> */}
					</Grid>
					<Grid item xs={12} lg={7}>
						<Box
							style={{
								boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
								padding: 20,
								borderRadius: 25,
							}}>
							<UpdateForm />
						</Box>
					</Grid>
				</Grid>
			</Container>
			{loading ? <LoaderModal /> : ''}
		</>
	);
};

export default UpdatePage;
