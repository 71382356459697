import React, { useEffect } from 'react';
import Header from '../components/resuableComponents/Header';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';
import { Container } from '@mui/material';

const TermandCondition = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<MainHeader />
			<Container style={{ marginTop: 20, marginBottom: 20 }}>
				<div class='containerp'>
					<header>
						<h1>Terms and Conditions</h1>
					</header>

					<section class='section'>
						<h3>1. Acceptance of Terms</h3>
						<p>
							By accessing, browsing, or using the Curelinea website (the
							"Website") or its services, you confirm that you accept and agree
							to these Terms. If you do not agree, please refrain from using the
							Website.
						</p>

						<h3>2. Changes to the Terms</h3>
						<p>
							We reserve the right to modify these Terms at any time. Any
							changes will be effective immediately upon posting on the Website.
							Your continued use of the Website after the posting of any changes
							constitutes your acceptance of those changes.
						</p>

						<h3>3. User Accounts</h3>
						<ul>
							<li>
								<strong>Account Information:</strong> You agree to provide
								accurate, current, and complete information during the
								registration process and to update it as needed.
							</li>
							<li>
								<strong>Account Security:</strong> You are responsible for
								maintaining the confidentiality of your login credentials and
								for all activities under your account.
							</li>
							<li>
								<strong>Account Termination:</strong> We reserve the right to
								suspend or terminate your account if we suspect any violation of
								these Terms, fraudulent activity, or any unauthorized use.
							</li>
						</ul>

						<h3>4. Subscription Services</h3>
						<ul>
							<li>
								<strong>Billing:</strong> If you subscribe to any of our paid
								plans, you will be billed on a recurring basis (monthly or
								annually).
							</li>
							<li>
								<strong>Automatic Renewal:</strong> Unless you cancel your
								subscription, it will renew automatically.
							</li>
							<li>
								<strong>Cancellation:</strong> You may cancel your subscription
								at any time via your account settings.
							</li>
						</ul>

						<h3>5. Use of the Website</h3>
						<p>
							You agree to use the Website for lawful purposes and in accordance
							with these Terms. Prohibited uses include:
						</p>
						<ul>
							<li>Violating applicable laws or regulations.</li>
							<li>
								Engaging in fraudulent activities, spam, or unsolicited
								advertising.
							</li>
							<li>Distributing viruses or any harmful code.</li>
						</ul>

						<h3>6. Intellectual Property</h3>
						<p>
							All content on the Website is owned by or licensed to us and is
							protected by intellectual property laws. You may not copy,
							reproduce, or distribute content without permission.
						</p>

						<h3>7. Third-Party Links</h3>
						<p>
							The Website may contain links to third-party websites. We are not
							responsible for the content or practices of these sites.
						</p>

						<h3>8. Disclaimer of Warranties</h3>
						<p>
							The Website and services are provided "as is" without warranties.
							We do not guarantee that the Website will be error-free or
							uninterrupted.
						</p>

						<h3>9. Limitation of Liability</h3>
						<p>
							We are not liable for any damages arising from your use of the
							Website, including errors, interruptions, or data loss.
						</p>

						<h3>10. Indemnification</h3>
						<p>
							You agree to indemnify and hold harmless Curelinea and its
							affiliates from any claims or liabilities arising from your use of
							the Website.
						</p>

						<h3>11. Termination</h3>
						<p>
							We reserve the right to terminate your access to the Website if
							you violate these Terms or engage in unlawful activity.
						</p>

						<h3>12. Governing Law</h3>
						<p>
							These Terms are governed by the laws of USA. Any disputes will be
							resolved in the courts of USA.
						</p>

						<h3>13. Severability</h3>
						<p>
							If any part of these Terms is found to be invalid, the remaining
							provisions will continue in full force.
						</p>

						<h3>14. Entire Agreement</h3>
						<p>
							These Terms constitute the entire agreement between you and
							Curelinea regarding your use of the Website.
						</p>
					</section>
				</div>
			</Container>
			<Footer />
		</>
	);
};

export default TermandCondition;
