import {
	Container,
	Grid,
	Box,
	useTheme,
	useMediaQuery,
	Button,
	Avatar,
} from '@mui/material';
import React from 'react';
import PayPalProvider from '../components/resuableComponents/PayPalProvider';
import PayPalButton from '../components/resuableComponents/PayPalButton';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/curelinea-gif-logo.gif';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginSuccess } from '../reducers/HomeReducer';
import { Popover } from 'antd';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';

const PaymentPage = () => {
	const location = useLocation();
	const theme = useTheme();
	const dispatch = useDispatch();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { userLogin } = useSelector((state) => state.home);
	const navigate = useNavigate();
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);
	return (
		<>
			<MainHeader />
			<Container>
				<Grid container>
					<Grid item xs={12} lg={4}></Grid>
					<Grid item xs={12} lg={4}>
						<div className='containerpay' style={{ marginTop: 50 }}>
							<h1>Complete Your Payment</h1>
							<p>Please proceed to make the payment using PayPal.</p>
							<PayPalProvider>
								<h1 style={{ fontSize: 16 }}>Pre Order with PayPal </h1>
								<PayPalButton amount={location.state} />
							</PayPalProvider>

							<p>
								If you have any questions, please{''}
								<a href='mailto:support@example.com' style={{ marginLeft: 5 }}>
									contact support
								</a>
								.
							</p>
						</div>
					</Grid>
					<Grid item xs={12} lg={4}></Grid>
				</Grid>
			</Container>
		</>
	);
};

export default PaymentPage;
