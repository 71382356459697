import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Grid, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Select, Space } from 'antd';
import { userRegisterDispatch } from '../reducers/HomeReducer';

const SignInSchema = Yup.object().shape({
	// name: Yup.string().required('Name Required'),
	email: Yup.string().required('Email Required'),
	// password: Yup.string()
	// 	.required('Password Required')
	// 	.min(8, 'Password must be at least 8 characters')
	// 	.matches(RegExp('(.*[a-z].*)'), 'Password must be at least 1 Lowercase')
	// 	.matches(RegExp('(.*[A-Z].*)'), 'Password must be at least 1 Uppercase')
	// 	.matches(RegExp('(.*\\d.*)'), 'Password must be at least 1 Number')
	// 	.matches(
	// 		RegExp('[!@#$%^&*(),.?":{}|<>]'),
	// 		'Password must be at least 1 Special'
	// 	),
});
const RegisterForm = ({ SetStatus, handleClose, setOpen }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	console.log('location', location);
	const [tab, setTab] = useState('member');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const handleTab = (val) => {
		setTab(val);
	};
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};
	const handleFormSubmit = (data) => {
		// alert(JSON.stringify(data));
		let form = data;
		console.log('form', form);
		dispatch(userRegisterDispatch(data, navigate));
	};
	return (
		<Formik
			initialValues={{
				// name: '',
				email: location?.state ? location?.state : '',
				// password: '',
				// mobile: '',
				// gender: '',
				// activitylevel: '',
				// dietarypreferences: '',
				// mealspreferday: '',
			}}
			enableReinitialize={true}
			validationSchema={SignInSchema}
			onSubmit={handleFormSubmit}>
			{(formik, values) => {
				const { errors, setFieldValue } = formik;
				return (
					<Form>
						<Box>
							<Box
								display={matches ? '' : 'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								mt={2}></Box>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Box>
										<Grid container style={{ padding: 15 }} spacing={3}>
											{/* <Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Name <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='name'
													type='text'
													placeHolder='Enter Your Name*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.name
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.name ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.name}
													</span>
												) : null}
											</Grid> */}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Email <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='email'
													type='email'
													placeHolder='Enter Your Email*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.email
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.email ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.email}
													</span>
												) : null}
											</Grid>
											{/* <Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Create Password{' '}
													<span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='password'
													type='password'
													placeHolder='Enter Your Password*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.password
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.password ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.password}
													</span>
												) : null}
											</Grid>{' '} */}
											{/* <Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Mobile Number <span style={{ color: 'red' }}>*</span>
												</span>
												<Field
													name='mobile'
													type='number'
													placeHolder='Enter Your Mobile Number*'
													style={{
														padding: 10,
														width: '95%',
														borderRadius: 5,
														marginTop: 5,
														border: errors?.mobile
															? '2px solid red'
															: '1px solid #6c757d',
													}}
													inputProps={{
														style: { padding: 12 },
													}}
												/>
												<br />
												{errors?.mobile ? (
													<span style={{ color: 'red', fontSize: 12 }}>
														{errors?.mobile}
													</span>
												) : null}
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Gender <span style={{ color: 'red' }}>*</span>
												</span>
												<br></br>
												<Select
													defaultValue='Select'
													name='gender'
													style={{
														width: '100%',
														borderRadius: 5,
														marginTop: 10,
														border: '1px solid #6c757d',
													}}
													onChange={(val) => setFieldValue('gender', val)}
													options={[
														{
															value: '',
															label: 'Select',
														},
														{
															value: 'Male',
															label: 'Male',
														},
														{
															value: 'Female',
															label: 'Female',
														},
														{
															value: 'Other',
															label: 'Other',
														},
													]}
												/>
												<br />
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													What is your activity level?{' '}
												</span>
												<br></br>
												<Select
													defaultValue='Select'
													style={{
														width: '100%',
														borderRadius: 5,
														marginTop: 10,
														border: '1px solid #6c757d',
													}}
													name='activitylevel'
													onChange={(val) =>
														setFieldValue('activitylevel', val)
													}
													options={[
														{
															value: '',
															label: 'Select',
														},
														{
															value: 'Sedentary (little or no exercise)',
															label: 'Sedentary (little or no exercise)',
														},
														{
															value:
																'Lightly Active (light exercise/sports 1-3 days/week)',
															label:
																'Lightly Active (light exercise/sports 1-3 days/week)',
														},
														{
															value:
																'Moderately Active (moderate exercise/sports 3-5 days/week)',
															label:
																'Moderately Active (moderate exercise/sports 3-5 days/week)',
														},
														{
															value:
																'Very Active (hard exercise/sports 6-7 days a week)',
															label:
																'Very Active (hard exercise/sports 6-7 days a week)',
														},
													]}
												/>
												<br />
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													Do you have any dietary preferences?{' '}
												</span>
												<br></br>
												<Select
													onChange={(val) =>
														setFieldValue('dietarypreferences', val)
													}
													name='dietarypreferences'
													defaultValue='Select'
													style={{
														width: '100%',
														borderRadius: 5,
														marginTop: 10,
														border: '1px solid #6c757d',
													}}
													options={[
														{
															value: '',
															label: 'Select',
														},
														{
															value: 'Vegetarian',
															label: 'Vegetarian',
														},
														{
															value: 'Vegan',
															label: 'Vegan',
														},
														{
															value: 'Non-Vegetarian',
															label: 'Non-Vegetarian',
														},
														{
															value: 'Gluten - Free',
															label: 'Gluten - Free',
														},
														{
															value: 'Dairy-Free',
															label: 'Dairy-Free',
														},
													]}
												/>
												<br />
											</Grid>{' '}
											<Grid item xs={12}>
												<span
													style={{
														color: '#6c757d',
													}}>
													How many meals do you prefer to have in a day?
												</span>
												<br></br>
												<Select
													name='mealspreferday'
													defaultValue='Select'
													style={{
														width: '100%',
														borderRadius: 5,
														marginTop: 10,
														border: '1px solid #6c757d',
													}}
													onChange={(val) =>
														setFieldValue('mealspreferday', val)
													}
													options={[
														{
															value: '',
															label: 'Select',
														},
														{
															value: '3 meals (Breakfast, Lunch, Dinner)',
															label: '3 meals (Breakfast, Lunch, Dinner)',
														},
														{
															value:
																'4 meals (Breakfast, Lunch, Snack, Dinner)',
															label:
																'4 meals (Breakfast, Lunch, Snack, Dinner)',
														},
														{
															value:
																'5 meals (Breakfast, Mid-Morning Snack, Lunch, Evening Snack, Dinner)',
															label:
																'5 meals (Breakfast, Mid-Morning Snack, Lunch, Evening Snack, Dinner)',
														},
													]}
												/>
												<br />
											</Grid>{' '} */}
											<Grid item xs={12}>
												<Button
													type='submit'
													style={{
														backgroundColor: '#185661',
														color: 'white',
														width: '100%',
														padding: 10,
														borderRadius: 10,
													}}>
													Register
												</Button>
												<p style={{ fontSize: 12, marginTop: 15 }}></p>
												<Box display={'flex'} alignItems={'center'} mt={2}>
													<p style={{ fontSize: 12 }}>
														Already have an account?
													</p>
													<Link
														to='/login'
														style={{ fontSize: 12, marginLeft: 5 }}>
														Login
													</Link>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};

export default RegisterForm;
