import React from 'react';
import { Popover } from 'antd';
import {
	Container,
	Grid,
	Box,
	useTheme,
	useMediaQuery,
	Button,
	Avatar,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/curelinea-gif-logo.gif';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginSuccess } from '../../reducers/HomeReducer';
const MainHeader = () => {
	const navigate = useNavigate();
	const { userLogin } = useSelector((state) => state.home);
	const theme = useTheme();
	const dispatch = useDispatch();
	const location = useLocation();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	console.log('location', location);
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);
	return (
		<>
			<Container style={{ marginTop: 20 }}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<Box>
						<Link to='/'>
							<img
								src={logo}
								style={{
									cursor: 'pointer',
									height: matches ? '40px' : '90px',
								}}
							/>
						</Link>
					</Box>
					<Box>
						{userLogin?.status === 'success' ? (
							<Popover content={content} title={`${userLogin?.result?.name}`}>
								<Avatar sx={{ bgcolor: '#13A8A6', cursor: 'pointer' }}>
									{userLogin?.result?.name.charAt(0)}
								</Avatar>
							</Popover>
						) : (
							<Link to='/login'>
								<Button
									style={{
										background: '#13A8A6',
										padding: '10px 40px',
										color: 'white',
										borderRadius: 25,
									}}>
									Login
								</Button>
							</Link>
						)}
					</Box>
				</Box>
				{location.pathname === '/generalQuestion' ? (
					''
				) : (
					<Button
						variant='contained'
						color='primary'
						style={{ background: '#13A8A6', color: 'white', marginTop: 20 }}
						onClick={() => {
							navigate(userLogin?.status === 'success' ? '/dashboard' : '/');
						}}>
						Back
					</Button>
				)}
			</Container>
		</>
	);
};

export default MainHeader;
