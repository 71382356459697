import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useDispatch } from 'react-redux';
import { ResponseGenerateDispatch } from '../../reducers/HomeReducer';
import { useNavigate } from 'react-router-dom';

const PayPalButton = ({ amount }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<PayPalButtons
			createOrder={(data, actions) => {
				return actions.order.create({
					purchase_units: [
						{
							amount: {
								value: `${amount}`, // Amount to be charged
							},
						},
					],
				});
			}}
			onApprove={async (data, actions) => {
				return actions.order.capture().then(function (details) {
					alert('Transaction completed by ' + details.payer.name.given_name);
					console.log('first', details);
					navigate('/dashboard');
				});
			}}
			onError={(err) => {
				console.error('PayPal error:', err);
			}}
		/>
	);
};

export default PayPalButton;
