import React, { useState } from 'react';
import { Grid, Button, Box, useTheme, useMediaQuery } from '@mui/material';
import { userRegisterDispatch } from '../../reducers/HomeReducer';
import contact1 from '../../assets/images/contact1.svg';
import contact2 from '../../assets/images/contact2.svg';
import contact3 from '../../assets/images/contact3.svg';
import contact4 from '../../assets/images/contact4.svg';
import mobile from '../../assets/images/mobileframe.png';
import start from '../../assets/images/start.png';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GetStarted = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [email, setEmail] = useState('');

	const handleRegister = () => {
		let form = {
			email: email,
		};
		dispatch(userRegisterDispatch(form, navigate));
	};
	return (
		<>
			<Grid container alignItems={'center'} style={{ marginTop: 100 }}>
				<Grid item xs={12} lg={6} style={{ textAlign: '-webkit-center' }}>
					<div style={{ overflow: 'hidden' }}>
						<img
							style={{
								position: 'absolute',
								zIndex: 1,
								height: matches ? 400 : 500,
								transform: matches
									? 'translate(35%, -12%)'
									: 'translate(100%, -12%)',
							}}
							src={mobile}
							alt='Screen Frame'
						/>
						<img
							className='move-image'
							src={start}
							style={{ height: matches ? 330 : '' }}
							alt='Pictures of Meals'
						/>
					</div>
				</Grid>
				<Grid item xs={12} lg={6} style={{ padding: 20 }} className='grad'>
					<Grid container style={{}} spacing={4}>
						<Grid item xs={12} lg={2}></Grid>
						<Grid item xs={12} lg={8}>
							{' '}
							<p
								style={{
									textAlign: 'center',
									color: 'rgb(19, 168, 166)',
									fontWeight: 'bold',
								}}>
								AS SEEN ON
							</p>
							<Box
								mt={matches ? 4 : ''}
								display={'flex'}
								alignItems={'center'}
								style={{
									background: 'white',
									borderRadius: 5,
									width: matches ? '' : '100%',
								}}>
								<img
									src={contact1}
									style={{
										height: matches ? 15 : 22,
										width: '100%',
										boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
										padding: 10,
										borderRadius: 10,
									}}
								/>
								<img
									src={contact2}
									style={{
										height: matches ? 15 : 22,
										marginLeft: 10,
										width: '100%',
										boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
										padding: 10,
										borderRadius: 10,
									}}
								/>
								<img
									src={contact3}
									style={{
										height: matches ? 15 : 20,
										marginLeft: 10,
										width: '100%',
										boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
										padding: 10,
										borderRadius: 10,
									}}
								/>
								<img
									src={contact4}
									style={{
										height: matches ? 15 : 20,
										marginLeft: 10,
										width: '100%',
										boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
										padding: 10,
										borderRadius: 10,
									}}
								/>
							</Box>
							<Box
								mt={4}
								style={{
									background: 'white',
									padding: matches ? 20 : 30,
									borderRadius: matches ? 0 : 10,
									width: matches ? '' : '100%',
									boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
								}}>
								<h1
									style={{
										fontSize: matches ? 25 : 30,
										fontWeight: 'bold',
										textAlign: 'center',
										// lineHeight: matches ? '' : 0,
										textShadow: '2px 2px 4px rgba(0,0,0,.3)',
									}}>
									PROVE TO YOURSELF IT WORKS
								</h1>
								{/* <h1
									style={{
										fontSize: 30,
										fontWeight: 'bold',
										textAlign: 'center',
										textShadow: '2px 2px 4px rgba(0,0,0,.3)',
									}}>
									IT WORKS
								</h1> */}
								<p
									style={{
										textAlign: 'center',
										lineHeight: 0,
										color: '#13A8A6',
										fontWeight: 'bold',
										textShadow: '2px 2px 4px rgba(0,0,0,.3)',
									}}>
									IN JUST SECONDS...
								</p>
								<h1
									style={{
										fontSize: matches ? 25 : 30,
										fontWeight: 'bold',
										textAlign: 'center',
										textShadow: '2px 2px 4px rgba(0,0,0,.3)',
									}}>
									TRY IT OUT{' '}
									<span
										style={{
											color: '#13A8A6',
											fontWeight: 'bold',
											textShadow: '2px 2px 4px rgba(0,0,0,.3)',
										}}>
										FREE
									</span>{' '}
									RIGHT NOW
								</h1>
								<input
									type='text'
									onChange={(e) => setEmail(e.target.value)}
									placeholder='Enter Your Email'
									style={{
										border: '1px solid grey',
										padding: 12,
										width: '90%',
										borderRadius: 30,
									}}
								/>
								<Button
									onClick={handleRegister}
									style={{
										background: '#13A8A6',
										width: '100%',
										textTransform: 'capitalize',
										color: '#fff',
										fontWeight: 'bold',
										marginTop: matches ? 20 : 50,
										fontSize: matches ? 12 : 16,
										padding: 10,
										borderRadius: 30,
									}}>
									Get Started
								</Button>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}></Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default GetStarted;
