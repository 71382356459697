import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import Header from '../components/resuableComponents/Header';
import { Skeleton, Image, Divider } from 'antd'; // Assuming you are using Ant Design
import { Popover } from 'antd';
import {
	Container,
	Box,
	Avatar,
	Button,
	useTheme,
	useMediaQuery,
	Grid,
} from '@mui/material';
import logo from '../assets/images/curelinea-gif-logo.gif';
import {
	desGenerateSuccess,
	imageGenerateSuccess,
	recipeGenerateSuccess,
	recipeTitleGenerateDispatch,
	userLoginSuccess,
} from '../reducers/HomeReducer';
import CircularProgress from '@mui/material/CircularProgress';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import Footer from '../components/resuableComponents/Footer';
import MainHeader from '../components/resuableComponents/MainHeader';
const RecipeTitlePage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const { loading, recipetitle } = useSelector((state) => state.home);
	const [title, setTitle] = useState('');
	const handleTitle = (val) => {
		setTitle(val);
	};
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const location = useLocation();
	// const [recipe, setRecipe] = useState(null);
	const handleSelection = () => {
		navigate('/recipegenerator', { state: title });
		dispatch(desGenerateSuccess(null));
		dispatch(imageGenerateSuccess(null));
		dispatch(recipeGenerateSuccess(null));
	};
	const { userLogin } = useSelector((state) => state.home);
	console.log('location', location);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	console.log('recipetitle', recipetitle?.choices[0]?.message?.content);
	let recipes = '';
	if (recipetitle?.choices[0]?.message?.content) {
		const htmlString = recipetitle?.choices[0]?.message?.content;

		// Step 1: Parse the HTML string
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');

		// Step 2: Extract the <li> elements
		const listItems = doc.querySelectorAll('li');

		// Step 3: Convert the NodeList to an array and extract text content
		recipes = Array.from(listItems).map((li) => li.textContent);

		// Output the extracted recipes

		// setRecipe(recipes);
	}

	console.log('firstrecipe', recipes);
	useEffect(() => {
		if (location.state) {
			let form = {
				content: location.state.promt,
			};
			dispatch(recipeTitleGenerateDispatch(form));
		}
		// const promt = `generate list of title for recipe ${rec} with ${rec2} for ${rec3} take the userData and reply with the following in  format  (DO NOT REMOVE THE TAGS):->for Example:->[{Title1},{Title2} and soon..] `;
	}, []);
	const content = (
		<div>
			<p>{userLogin?.result?.email}</p>
			<Button
				onClick={() => {
					navigate('/');
				}}>
				Home
			</Button>
			<Button
				onClick={() => {
					sessionStorage.removeItem('userLogin');
					dispatch(userLoginSuccess(null));
					navigate('/');
				}}>
				Logout
			</Button>
		</div>
	);

	return (
		<>
			<MainHeader />
			<Container>
				<p>
					Recipe /{' '}
					{`${location.state.rec3} style ${location.state.resp} ${location.state.rec} recipe`}
				</p>
				<Grid
					container
					style={{
						marginTop: 50,
						boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
						borderRadius: 12,
						padding: 20,
						marginBottom: 50,
					}}>
					<Grid item xs={12} lg={2}></Grid>
					<Grid item xs={12} lg={8}>
						<Box>
							<h1>Choose a Title</h1>
							<p>
								{`Pick your favourite ${location.state.rec3} style ${location.state.resp} ${location.state.rec} from AI-generated
								options below.We'll use our AI to generate a list of ingredients
								and direction based on your selection`}
							</p>
						</Box>
						<Grid container spacing={matches ? 2 : 4} style={{ marginTop: 20 }}>
							{recipes?.length > 0 &&
								recipes.map((i, index) => {
									return (
										<Grid item xs={12} lg={4}>
											<Box
												onClick={() => handleTitle(i)}
												style={{
													border:
														title === i
															? '1px solid rgb(19, 168, 166)'
															: '1px solid #e0e0e0',
													background:
														title === i ? 'rgb(19 168 166 / 12%)' : '',
													padding: 20,
													borderRadius: 12,
													cursor: 'pointer',
													boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
												}}>
												<p style={{ textAlign: 'center' }}>{i}</p>
											</Box>
										</Grid>
									);
								})}
						</Grid>
						<Button
							onClick={() => handleSelection()}
							disabled={title ? false : true}
							style={{
								background: title ? 'rgb(19, 168, 166)' : 'lightgrey',
								width: '100%',
								color: 'white',
								textTransform: 'capitalize',
								padding: 12,
								fontSize: 16,
								borderRadius: 50,
								marginTop: 50,
								marginBottom: 50,
							}}>
							Create Custom Recipe
						</Button>
					</Grid>
					<Grid item xs={12} lg={2}></Grid>
				</Grid>
			</Container>
			{/* <Footer /> */}
			{loading ? <LoaderModal /> : ''}
			{matches ? '' : <Box className='bgs'></Box>}
			{matches ? '' : <Box className='bgss'></Box>}
		</>
	);
};

export default RecipeTitlePage;
