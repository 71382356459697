import React, { useRef, useEffect } from 'react';
import { Grid, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionDispatch } from '../../reducers/HomeReducer';
import bg from '../../assets/images/bg-1.png';
import keto from '../../assets/images/keto.png';
import { setCateg, setUpgrade } from '../../reducers/UiReducer';
import UpgradeModal from './UpgradeModal';

const quiz = [
	{
		cat: 'Weight Loss',
		question: [
			{ quest: 'What is your gender?', opt: ['Male', 'Female', 'Others'] },
			{
				quest: 'What is your activity level?',
				opt: [
					'Sedentary (little or no exercise)',
					'Lightly Active (light exercise/sports 1-3 days/week)',
					'Moderately Active (moderate exercise/sports 3-5 days/week)',
					'Very Active (hard exercise/sports 6-7 days a week)',
				],
			},
			{
				quest: 'Do you have any dietary preferences?',
				opt: [
					'Vegetarian',
					'Vegan',
					'Non-Vegetarian',
					'Gluten-Free',
					'Dairy-Free',
				],
			},
		],
	},
];
const Category = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { categ, userLogin, mealplan } = useSelector((state) => state.home);
	console.log('location', location);
	const handle = () => {};

	const sliderRef = useRef(null);

	useEffect(() => {
		if (matches) {
		} else {
			const slider = sliderRef.current;
			let scrollAmount = 0;

			const autoSlide = setInterval(() => {
				if (scrollAmount < slider.scrollWidth - slider.clientWidth) {
					scrollAmount += 1;
					slider.scrollLeft = scrollAmount;
				} else {
					scrollAmount = 0;
					slider.scrollLeft = 0;
				}
			}, 20); // Adjust the speed (lower is faster)

			return () => clearInterval(autoSlide);
		}
	}, []);
	return (
		<>
			<div
				style={{
					// background: `url(${bg})`,
					// height: '500px',
					// borderRadius: 20,
					// marginBottom: 50,
					marginTop: location.pathname === '/dashboard' ? '' : matches ? 0 : '',
					padding: matches ? '' : 60,
					// backgroundImage: 'linear-gradient(to right, #13a8a6, #48d0ac)',
				}}>
				<Container className={matches ? 'grad' : ''}>
					<h1
						onClick={handle}
						style={{
							textAlign: 'center',
							fontSize: matches ? 37 : 46,
							fontWeight: 'bold',
							textShadow: '2px 2px 4px rgba(0,0,0,.3)',
						}}>
						Make your selection
					</h1>
					<p style={{ textAlign: 'center', fontSize: 20 }}>
						Choose a protein, nutritional style, and cuisine to get started
					</p>
					<Box
						mt={6}
						display={matches ? '' : 'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						{matches ? (
							<Grid container spacing={matches ? 3 : 10}>
								{categ?.categ?.map((i) => {
									return (
										<Grid
											item
											xs={6}
											style={{ textAlign: 'center' }}
											lg={matches ? 1.5 : 1.6}>
											<Box
												onClick={() => {
													if (userLogin?.status === 'success') {
														if (
															userLogin?.result?.gender &&
															userLogin?.result?.activitylevel &&
															userLogin?.result?.dietarypreferences &&
															userLogin?.result?.mealspreferday
														) {
															if (mealplan?.result?.length > 0) {
																dispatch(setUpgrade(true));
															} else {
																navigate('/mealmentor', { state: i });
																dispatch(QuestionDispatch(i.id));
																dispatch(setCateg(i.id));
															}
														} else {
															navigate('/generalQuestion');
														}
													} else {
														navigate('/login');
													}
												}}
												style={{
													background: 'white',
													padding: 10,
													borderRadius: 15,
													textAlign: 'center',
													cursor: 'pointer',

													boxShadow:
														'rgb(19, 167, 165) 6px 6px 1px, rgba(149, 157, 165, 0.2) -6px -6px 5px',
												}}>
												<img src={i.imgpath} style={{ height: 60 }} />
											</Box>
											<p style={{ marginTop: 10 }}>{i.name}</p>
										</Grid>
									);
								})}
							</Grid>
						) : (
							<div ref={sliderRef} className='slider-wrapper'>
								{categ?.categ?.map((item, index) => (
									<div key={index} className='slide'>
										<Box
											onClick={() => {
												if (userLogin?.status === 'success') {
													if (
														userLogin?.result?.gender &&
														userLogin?.result?.activitylevel &&
														userLogin?.result?.dietarypreferences &&
														userLogin?.result?.mealspreferday
													) {
														if (mealplan?.result?.length > 0) {
															dispatch(setUpgrade(true));
														} else {
															navigate('/mealmentor', { state: item });
															dispatch(QuestionDispatch(item.id));
															dispatch(setCateg(item.id));
														}
													} else {
														navigate('/generalQuestion');
													}
												} else {
													navigate('/login');
												}
											}}
											style={{
												background: 'white',
												padding: 10,
												borderRadius: 15,
												textAlign: 'center',
												cursor: 'pointer',

												boxShadow:
													'rgb(19, 167, 165) 6px 6px 1px, rgba(149, 157, 165, 0.2) -6px -6px 5px',
											}}>
											<img src={item.imgpath} alt={item.name} />
										</Box>
										<p title={item.name}>{item.name}</p>
									</div>
								))}
							</div>
						)}
					</Box>
				</Container>
				{/* <Box className='bgs'></Box> */}
				{matches ? '' : <Box className='bgss'></Box>}
			</div>
			<UpgradeModal />
		</>
	);
};

export default Category;
