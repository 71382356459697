import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setUpgrade } from '../../reducers/UiReducer';
import { useNavigate } from 'react-router-dom';
const UpgradeModal = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { upgrade } = useSelector((state) => state.ui);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		dispatch(setUpgrade(false));
	};
	return (
		<>
			<Modal
				title='Upgrade to Pro'
				open={upgrade}
				// onOk={handleOk}
				onCancel={handleCancel}
				footer={(_, { OkBtn, CancelBtn }) => (
					<>
						<CancelBtn />
					</>
				)}>
				<p>Unlock premium features by upgrading to our Pro version!</p>
				<Button
					onClickCapture={() => {
						dispatch(setUpgrade(false));
					}}
					style={{ background: 'rgb(11, 78, 89)', color: 'white' }}
					onClick={() => navigate('/plan')}>
					Upgrade Now
				</Button>
			</Modal>
		</>
	);
};
export default UpgradeModal;
